import { connect } from 'react-redux';

import {
    fetchABTestsDataModule,
    getABTestEnabled,
} from '@perpay-web/fintech/dataModules/fetchABTests';
import {
    METAL_CARD_STORE_NAME,
    METAL_CARD_API_NAME,
} from '@perpay-web/constants/experiments';
import { METAL_CARD_IS_LAUNCHED } from '@perpay-web/fintech/constants/flags';

import CardOnboardingSwitcher from '@perpay-web/fintech/components/screens/CardOnboardingSwitcher/CardOnboardingSwitcher';
import {
    cardOnboardingSwitcherDataModule,
    getIsFirstTimeBorrower,
} from '@perpay-web/fintech/dataModules/composed/cardOnboardingSwitcher';
import { getIsCardFirstSignupFlow } from '@perpay-web/fintech/selectors/ui/signup';

const mapDataModuleStateToProps = (state) => {
    const isLoading =
        cardOnboardingSwitcherDataModule.getIsLoadingOrUnrequested(state);
    const isFirstTimeBorrower = getIsFirstTimeBorrower(state);
    const isCardFirstUser = getIsCardFirstSignupFlow(state);
    const isMetalCard =
        METAL_CARD_IS_LAUNCHED &&
        getABTestEnabled(state, METAL_CARD_STORE_NAME);

    return {
        isLoading,
        isFirstTimeBorrower,
        isCardFirstUser,
        isMetalCard,
    };
};

const mapDataModuleDispatchToProps = (dispatch) => ({
    onMount: () => {
        dispatch(
            cardOnboardingSwitcherDataModule.dataRequest({
                actions: [
                    fetchABTestsDataModule.dataRequest(METAL_CARD_API_NAME),
                ],
            }),
        );
    },
    onUnmount: () => {
        dispatch(cardOnboardingSwitcherDataModule.dataReset());
    },
});

// Legacy usage of Redux connect API. New code should not use this pattern

const CardOnboardingSwitcherContainer = connect(
    mapDataModuleStateToProps,
    mapDataModuleDispatchToProps,
)(CardOnboardingSwitcher);

export default CardOnboardingSwitcherContainer;
