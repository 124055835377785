import { useDispatch } from 'react-redux';

import { analyticsPerpayCardCreditLimitIncreaseNotificationViewed } from '@perpay-web/fintech/actions/analytics/perpayCardCreditLimitIncreaseNotification';
import CardCreditLimitIncreaseNotification from '@perpay-web/fintech/components/composite/CardCreditLimitIncreaseNotification/CardCreditLimitIncreaseNotification';

const CardCreditLimitIncreaseNotificationContainer = ({
    newCreditLimitAmount,
}) => {
    const dispatch = useDispatch();

    const onMount = () =>
        dispatch(analyticsPerpayCardCreditLimitIncreaseNotificationViewed());

    return (
        <CardCreditLimitIncreaseNotification
            onMount={onMount}
            newCreditLimitAmount={newCreditLimitAmount}
        />
    );
};

export default CardCreditLimitIncreaseNotificationContainer;
