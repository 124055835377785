import { useCallback, useEffect, useRef, useSyncExternalStore } from 'react';

import {
    SUPPORT_CLICK_SUPPORT,
    SUPPORT_MESSAGE_KEY,
    SUPPORT_OPENED,
    SUPPORT_CLOSED,
    SUPPORT_REDIRECT_SELF_ONBOARDING,
} from '@perpay-web/constants/messages';
import {
    SELF_HELP_URL,
    SELF_ONBOARDING_VALIDATION_URL,
} from '@perpay-web/constants/urls';
import { useIsSemiLarge } from '@perpay-web/hooks/useIsSemiLarge';

import styles from '@perpay-web/components/base/SupportTool/SupportTool.scss';
import { getClassName } from '@perpay-web/utils/domUtils';

function getReadyStateSnapshot() {
    return document.readyState;
}

function subscribeLoadListener(callback) {
    window.addEventListener('load', callback);

    return () => {
        window.removeEventListener('load', callback);
    };
}

const SupportTool = ({
    className,
    closeHandler,
    isAuthenticated = true,
    intercomBucket = null,
    internallyOpened,
    openHandler,
    onLoad,
    onOpen,
    opened,
    LinkComponent,
    placement = 'left',
}) => {
    const iframeRef = useRef(null);
    const selfOnboardingValidationLinkRef = useRef(null);
    const { isSemiLarge } = useIsSemiLarge();

    const documentReadyState = useSyncExternalStore(
        subscribeLoadListener,
        getReadyStateSnapshot,
    );

    const onMessage = useCallback(
        (event) => {
            const { data } = event;

            if (typeof data !== 'string') {
                return;
            }

            if (data.indexOf('self_help_tool.message') === -1) {
                return;
            }

            const dataJson = JSON.parse(data);

            if (dataJson['self_help_tool.message'] === SUPPORT_OPENED) {
                openHandler(true, null);
                onOpen();
                return;
            }

            if (dataJson['self_help_tool.message'] === SUPPORT_CLOSED) {
                closeHandler();
                return;
            }

            if (
                dataJson['self_help_tool.message'] ===
                SUPPORT_REDIRECT_SELF_ONBOARDING
            ) {
                selfOnboardingValidationLinkRef.current.click();
                return;
            }

            // We don't know what event it is.
            throw new Error('Unhandled self_help_tool.message');
        },
        [openHandler, onOpen, closeHandler],
    );

    useEffect(() => {
        window.addEventListener('message', onMessage);

        return () => {
            window.removeEventListener('message', onMessage);
        };
    }, [onMessage]);

    useEffect(() => {
        if (opened && !internallyOpened && documentReadyState === 'complete') {
            const message = {
                [SUPPORT_MESSAGE_KEY]: SUPPORT_CLICK_SUPPORT,
            };

            if (intercomBucket) {
                message.intercomBucket = intercomBucket;
            }

            iframeRef.current.contentWindow.postMessage(
                JSON.stringify(message),
                '*',
            );
        }
    }, [documentReadyState, intercomBucket, internallyOpened, opened]);

    if (!documentReadyState) return null;

    const getIsHidden = () => {
        if (!isAuthenticated) {
            return true;
        }
        if (!isSemiLarge && !opened) {
            return true;
        }
        return false;
    };
    const isHidden = getIsHidden();

    return (
        <>
            {opened && <div className={styles['support-tool__background']} />}
            {/*
                when the user is unauthenticated, the iframe is used to set the csrf
                cookie, and when signed in, the iframe serves the self-help tool.
                the iframe's key is set to `!isAuthenticated`, so it reloads when
                its value toggles
            */}
            {documentReadyState === 'complete' && (
                <iframe
                    className={getClassName(
                        styles['support-tool'],
                        styles[`support-tool--${placement}`],
                        opened && styles['support-tool--opened'],
                        isHidden && styles.hidden,
                        className,
                    )}
                    onLoad={onLoad}
                    onError={onLoad}
                    key={!isAuthenticated}
                    ref={iframeRef}
                    src={SELF_HELP_URL}
                    title='self-help-tool'
                />
            )}
            <LinkComponent
                className={styles.hidden}
                href={SELF_ONBOARDING_VALIDATION_URL}
                ref={selfOnboardingValidationLinkRef}
            />
        </>
    );
};

export default SupportTool;
