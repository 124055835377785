import { EpicManager } from '@perpay-web/services/EpicManager';
import { cardManualPaymentSetupDataModule } from '@perpay-web/fintech/dataModules/composed/cardManualPaymentSetup';
import { cardAccountApplicationCrudDataModule } from '@perpay-web/fintech/dataModules/crud/cardAccountApplication';
import { cardStatementsDataModule } from '@perpay-web/fintech/dataModules/composed/cardStatements';
import { ordersDataModule } from '@perpay-web/fintech/dataModules/composed/orders';
import { cardOnboardingSwitcherDataModule } from '@perpay-web/fintech/dataModules/composed/cardOnboardingSwitcher';
import { userInfoForCardOnboardingMVP2CrudDataModule } from '@perpay-web/fintech/dataModules/crud/userInfoForCardOnboardingMVP2';
import { payrollInstructionsFlowDataModule } from '@perpay-web/fintech/dataModules/composed/payrollInstructionsFlow';
import { cardPaymentSummaryDataModule } from '@perpay-web/fintech/dataModules/composed/cardPaymentSummary';
import { directDepositDetailsDataModule } from '@perpay-web/fintech/dataModules/composed/directDepositDetails';
import { cardCreateAutoPayDataModule } from '@perpay-web/fintech/dataModules/composed/cardCreateAutoPay';
import { bankAccountsForCardAutoPayDataModule } from '@perpay-web/fintech/dataModules/crud/bankAccountsForCardAutoPay';
import { directDepositUpdateEmployerAndAmountDataModule } from '@perpay-web/fintech/dataModules/composed/directDepositUpdateEmployerAndAmount';
import { directDepositUpdateManualPaymentSetupDataModule } from '@perpay-web/fintech/dataModules/composed/directDepositUpdateManualPaymentSetup';
import { updateDirectDepositDataModule } from '@perpay-web/fintech/dataModules/composed/updateDirectDeposit';
import { perpayPlusDataModule } from '@perpay-web/fintech/dataModules/composed/perpayPlus';
import { borrowerCreditsDataModule } from '@perpay-web/fintech/dataModules/composed/borrowerCredits';
import { marketplaceDetailsDataModule } from '@perpay-web/fintech/dataModules/composed/marketplaceDetails';
import { featureEnrollmentsForPerpayPlusCrudDataModule } from '@perpay-web/fintech/dataModules/crud/featureEnrollmentsForPerpayPlus';
import { cardActivityDataModule } from '@perpay-web/fintech/dataModules/composed/cardActivity';
import { checkoutDataModule } from '@perpay-web/fintech/dataModules/composed/checkout';
import { dashboardDataModule as dashboardV2DataModule } from '@perpay-web/fintech/dataModules/composed/dashboardV2';
import { pinwheelDataModule } from '@perpay-web/fintech/dataModules/composed/pinwheel';
import { hookActionEpic } from '@perpay-web/services/singletons';
import {
    cleanupAlgoliaOnLogout,
    cleanupMagentoTokenOnLogout,
} from '@perpay-web/storefront/epics/authentication/storefrontTokens';
import {
    analyticsIterablePushNotificationOpen,
    navigateOnUrlPush,
} from '@perpay-web/fintech/epics/utils/push';
import {
    actionsToNativeFromWeb,
    actionsToWebFromNative,
} from '@perpay-web/native-client/epics';
import { marketplaceDetailsNotificationsDataModule } from '@perpay-web/fintech/dataModules/composed/marketplaceDetailsNotifications';
import { cardDetailsNotificationsDataModule } from '@perpay-web/fintech/dataModules/composed/cardDetailsNotifications';
import { fetchCardDetailsV2DataModule } from '@perpay-web/fintech/dataModules/composed/fetchCardDetailsV2';
import { reportABTestVariant as reportABTestVariantForDataModule } from './analytics/abTests';
import {
    authenticationSuccess,
    login,
    refreshToken,
    logoutToken,
    resetPassword,
    recaptchaActive,
    recaptchaReset,
    logoutAuthService,
    initializeIdentity,
    resendMFA,
    verifyMFA,
} from './apis/authentication';
import { createEvent } from './apis/events';
import { fetchExternalCards, createExternalCard } from './apis/externalCards';
import {
    fetchBanks,
    createBank,
    verifyBank,
    createBankAccount,
    fetchBankAccounts,
} from './apis/banks';
import { fetchCompanies, fetchCompaniesSelfOnboarding } from './apis/companies';
import {
    fetchPayrollInstructions,
    fetchPayrollInstructionsForDataModule,
} from './apis/instructions';
import {
    getJobs,
    checkoutUpdateEmployeeID,
    createPrimaryJob,
    fetchJobsForDataModule,
    updateJob,
} from './apis/jobs';
import {
    fetchOrders,
    checkoutCreateOrder,
    checkoutUpdateOrder,
    cancelOrderFromModal,
    refreshOrder,
    fetchOrdersForDataModule,
} from './apis/orders';
import { checkoutLoanDecision } from './apis/orderReview';
import {
    fetchUserInfo,
    updateUserInfo,
    checkoutUpdateUserInfo,
    checkoutVerifyIdentity,
    deactivateAccount,
    cardUpdateUserInfo,
    fetchUserInfoForDataModule,
    updateUserInfoForDataModule,
} from './apis/userInfo';
import { fetchAccountSummary } from './apis/accountSummary';
import {
    createWithdrawalRequest,
    fetchWithdrawalBalance,
    fetchWithdrawalBalanceV2,
} from './apis/withdrawal';
import {
    primaryJob,
    salaryInfo,
    setPhone,
    signup,
    verifyCardInviteCode,
    verifyPhone,
} from './apis/signup';
import { reportError } from './errors';
import { createPaystub, pollPaystub } from './apis/paystubs';
import {
    fetchUserStatus,
    fetchUserStatusForDataModule,
} from './apis/userStatus';
import { getOrderCancellationReasons } from './apis/orderCancellationReasons';
import { getAccountDeactivationReasons } from './apis/accountDeactivationReasons';
import { getAccountDeactivationEligibility } from './apis/accountDeactivationEligibility';
import { fetchAds } from './apis/ads';
import {
    postAuthRedirect,
    routeToJwtLocation,
    routeToLocation,
} from './router';
import { createDeposit } from './apis/deposits';
import { createPaymentPlan } from './apis/paymentPlans';
import { fetchPinwheelEligibility } from './apis/pinwheelEligibility';
import { getABTest, reportABTestVariant, fetchABTests } from './apis/abTests';
import { fetchFeatureSwitch } from './apis/featureSwitch';
import { fetchPinwheelSurvey } from './apis/surveys';
import { createSurveyResponse } from './apis/surveyResponses';
import {
    createDeduction,
    fetchDeductions,
    fetchDeductionsForDataModule,
    pinwheelTransitionToModal,
    pinwheelSplitTransitionToModal,
    cardPinwheelTransitionToModal,
    updateDirectDepositPinwheelTransitionToModal,
    updateDeduction,
    updateDeductionAccountId,
} from './apis/deductions';
import { verifyIdentity } from './apis/identityVerification';
import { appUpdate } from './apis/appUpdate';
import { analyticsBuffer } from './apis/analytics';
import { fetchFeatures } from './apis/features';
import {
    enrollFeature,
    reEnableFeature,
    fetchEnrolledFeatures,
    disableFeature,
    fetchFeatureEnrollments,
    createFeatureEnrollment,
} from './apis/featureEnrollments';
import { fetchCreditScoreHistory } from './apis/creditScoreHistory';
import { fetchCreditScoresSummary } from './apis/creditScoresSummary';
import {
    acknowledgeNotification,
    fetchNotifications,
} from './apis/notifications';
import {
    createCardAccountApplications,
    fetchCardAccountApplication,
    pollCardAccountApplicationStatus,
    updateCardAccountApplication,
} from './apis/cardAccountApplications';
import {
    pinwheelCreatePrimaryJob,
    pinwheelUpdateSalaryInfo,
} from './ui/pinwheel';
import {
    pinwheelSplitCreatePrimaryJob,
    pinwheelSplitUpdateSalaryInfo,
} from './ui/pinwheelSplitSetUpPayments';
import {
    fetchPaymentSetupData,
    paymentSetupCreatePrimaryJob,
} from './ui/paymentSetup';
import {
    fetchPerpaySplitSetUpPaymentsData,
    perpaySplitPrimaryJob,
    perpaySplitSalaryInfo,
    perpaySplitCreateBank,
} from './ui/perpaySplitSetUpPayments';
import {
    bankScreenCreateBank,
    createBankAccountForCardAutoPay,
    fetchBankAccountsForCardAutoPay,
    verifySelectBank,
    verifySingleBank,
} from './ui/banks';
import { createWithdrawalRequestUI } from './ui/withdrawal';
import { profileCreateExternalCard } from './ui/externalCards';
import { paymentPortalCreateExternalCard } from './ui/paymentPortal';
import {
    updateEmploymentUI,
    updatePaycycleUI,
    cardUpdateSalaryInfo,
    cardCreatePrimaryJob,
} from './ui/employment';
import {
    updateCardAutoPayStepAfterDateSelection,
    updateCardAutoPayStateAfterFrequencySelection,
    cancelAutoPaySchedule,
    createAutoPayScheduleForCardAutoPay,
} from './ui/autoPaySchedules';
import { initCordovaOnIdentify } from './utils/cordova';
import { cardActivate } from './apis/cardActivation';
import {
    cardPinwheelTransitionToModalMVP2,
    creditUnfreezeCardAccountApplication,
    fetchCardAccountApplicationForOnboarding,
    fetchFeaturesForOnboarding,
    processCardApplication,
    processCardApplicationAddress,
    updateCardAccountApplicationForOnboarding,
} from './ui/cardOnboarding';
import { cardOnboardingMetalReplaceMaterial } from './ui/cardOnboardingMetal';
import { cardConfirmEmployerSetNextStep } from './ui/cardConfirmEmployer';
import {
    replaceAmountToChargeForUpdateDirectDeposit,
    createPrimaryJobSetStepToUpdate,
    updateJobSetStepToUpdate,
    setStepsForUpdateDirectDeposit,
    acceptCreditLimit,
} from './ui/updateDirectDeposit';
import { fetchCoreSummary } from './apis/coreSummary';
import {
    createAutoPaySchedule,
    fetchAutoPaySchedules,
    updateAutoPaySchedule,
} from './apis/autoPaySchedules';
import {
    downloadCardStatement,
    fetchCardStatements,
} from './apis/cardStatements';
import { fetchBorrowerCreditSummary } from './apis/borrowerCreditSummary';
import { fetchCardAccount } from './apis/cardAccounts';
import { fetchCardActivation } from './ui/cardActivation';
import { fetchInitiatedAutoPayPayments } from './apis/autoPayPayments';
import { fetchPaymentSetupSummary } from './apis/paymentSetupSummary';
import { fetchPinwheelABTest } from './apis/pinwheelABTest';
import { gateCardAccount } from './ui/gateCardAccount';
import { gateCardApply } from './ui/gateCardApply';
import { fetchGeneralAccountSummary } from './apis/generalAccountSummary';
import { fetchReferralStats } from './apis/referralStats';
import { updateUserInfoForCardOnboardingMVP2 } from './ui/userInfoForCardOnboardingMVP2';
import { uploadCardDocuments } from './apis/cardDocuments';
import { validateAddressesForMVP2 } from './apis/validateAddress';
import { fetchBorrowerCredits } from './apis/borrowerCredits';
import { fetchActivePayments } from './apis/payments';
import { fetchCardActivity } from './apis/cardActivity';
import {
    createFeatureEnrollmentForPerpayPlus,
    fetchFeatureEnrollmentsForPerpayPlus,
} from './ui/featureEnrollments';
import { fetchCreditLimits, updateCreditLimit } from './apis/creditLimits';
import { fetchTransactionDetails } from './apis/transactionDetails';
import { fetchCardDashboardSummary } from './apis/cardDashboardSummary';
import { fetchMarketplaceDashboardSummary } from './apis/marketplaceDashboardSummary';
import { fetchPlatformDashboardNotifications } from './apis/dashboardPlatformNotifications';
import { fetchPerpayPlusDashboardSummary } from './apis/perpayPlusDashboardSummary';
import { fetchDirectDepositDashboardSummary } from './apis/directDepositSummary';
import { fetchLearnMore } from './apis/learnMore';
import { launchSupportTool } from './ui/supportTool';
import { fetchAddressSuggestionsForDataModule } from './ui/addressSuggestions';
import { fetchAddressDetailsForDataModule } from './ui/addressDetails';
import {
    partnerOnboardingSignup,
    verifyPartnerOnboardingCode,
    partnerOnboardingSubmitCardApplication,
    createPassword,
} from './apis/partnerOnboarding';
import {
    fetchPartnerHostedCardApplication,
    partnerOnboardingPollApplicationStatus,
} from './ui/partnerOnboardingCardApplication';

const staticEpics = [
    acceptCreditLimit,
    acknowledgeNotification,
    analyticsBuffer,
    appUpdate,
    bankScreenCreateBank,
    cancelOrderFromModal,
    cardActivate,
    cardCreatePrimaryJob,
    cardPinwheelTransitionToModal,
    cardPinwheelTransitionToModalMVP2,
    cardOnboardingMetalReplaceMaterial,
    cardUpdateSalaryInfo,
    cardUpdateUserInfo,
    checkoutCreateOrder,
    checkoutLoanDecision,
    checkoutUpdateEmployeeID,
    checkoutUpdateOrder,
    checkoutUpdateUserInfo,
    checkoutVerifyIdentity,
    createBank,
    createDeduction,
    createDeposit,
    createEvent,
    createExternalCard,
    createPassword,
    createPaymentPlan,
    createPaystub,
    createPrimaryJobSetStepToUpdate,
    createSurveyResponse,
    createWithdrawalRequest,
    createWithdrawalRequestUI,
    deactivateAccount,
    cardConfirmEmployerSetNextStep,
    disableFeature,
    enrollFeature,
    fetchABTests,
    fetchAccountSummary,
    fetchAds,
    fetchBanks,
    fetchCompanies,
    fetchCompaniesSelfOnboarding,
    fetchCreditLimits,
    fetchCreditScoreHistory,
    fetchCreditScoresSummary,
    fetchDeductions,
    fetchDeductionsForDataModule,
    fetchEnrolledFeatures,
    fetchExternalCards,
    fetchFeatures,
    fetchNotifications,
    fetchOrders,
    fetchOrdersForDataModule,
    fetchPaymentSetupData,
    paymentSetupCreatePrimaryJob,
    fetchPayrollInstructions,
    fetchPerpaySplitSetUpPaymentsData,
    fetchPinwheelEligibility,
    fetchPinwheelSurvey,
    fetchTransactionDetails,
    fetchUserInfo,
    fetchUserStatus,
    fetchWithdrawalBalance,
    fetchWithdrawalBalanceV2,
    fetchAddressSuggestionsForDataModule,
    fetchAddressDetailsForDataModule,
    getABTest,
    getAccountDeactivationEligibility,
    getAccountDeactivationReasons,
    getJobs,
    getOrderCancellationReasons,
    initCordovaOnIdentify,
    initializeIdentity,
    authenticationSuccess,
    login,
    logoutAuthService,
    logoutToken,
    paymentPortalCreateExternalCard,
    perpaySplitCreateBank,
    perpaySplitPrimaryJob,
    perpaySplitSalaryInfo,
    pinwheelCreatePrimaryJob,
    pinwheelSplitCreatePrimaryJob,
    pinwheelSplitTransitionToModal,
    pinwheelSplitUpdateSalaryInfo,
    pinwheelTransitionToModal,
    pinwheelUpdateSalaryInfo,
    pollCardAccountApplicationStatus,
    pollPaystub,
    primaryJob,
    processCardApplication,
    processCardApplicationAddress,
    profileCreateExternalCard,
    recaptchaActive,
    recaptchaReset,
    reEnableFeature,
    refreshOrder,
    refreshToken,
    replaceAmountToChargeForUpdateDirectDeposit,
    reportABTestVariant,
    reportABTestVariantForDataModule,
    reportError,
    resendMFA,
    resetPassword,
    routeToJwtLocation,
    routeToLocation,
    salaryInfo,
    setPhone,
    setStepsForUpdateDirectDeposit,
    signup,
    updateCardAutoPayStepAfterDateSelection,
    updateCardAutoPayStateAfterFrequencySelection,
    updateDeduction,
    updateDeductionAccountId,
    updateDirectDepositPinwheelTransitionToModal,
    updateEmploymentUI,
    updateJobSetStepToUpdate,
    updatePaycycleUI,
    updateUserInfo,
    verifyBank,
    verifyIdentity,
    verifyPhone,
    verifyCardInviteCode,
    verifyMFA,
    verifySelectBank,
    verifySingleBank,
    cancelAutoPaySchedule,
    fetchCoreSummary,
    createAutoPaySchedule,
    createAutoPayScheduleForCardAutoPay,
    createBankAccount,
    createBankAccountForCardAutoPay,
    createCardAccountApplications,
    createPrimaryJob,
    creditUnfreezeCardAccountApplication,
    downloadCardStatement,
    fetchAutoPaySchedules,
    fetchBankAccounts,
    fetchBankAccountsForCardAutoPay,
    fetchBorrowerCreditSummary,
    fetchCardAccountApplicationForOnboarding,
    fetchCardAccountApplication,
    fetchCardAccount,
    fetchCardActivation,
    fetchCardStatements,
    fetchDeductionsForDataModule,
    fetchFeaturesForOnboarding,
    fetchInitiatedAutoPayPayments,
    fetchJobsForDataModule,
    fetchPaymentSetupSummary,
    fetchPayrollInstructionsForDataModule,
    fetchPinwheelABTest,
    fetchUserInfoForDataModule,
    fetchUserStatusForDataModule,
    gateCardAccount,
    gateCardApply,
    fetchGeneralAccountSummary,
    fetchReferralStats,
    updateAutoPaySchedule,
    updateCardAccountApplication,
    updateCardAccountApplicationForOnboarding,
    updateCreditLimit,
    updateJob,
    updateUserInfoForDataModule,
    updateUserInfoForCardOnboardingMVP2,
    uploadCardDocuments,
    validateAddressesForMVP2,
    fetchBorrowerCredits,
    fetchFeatureEnrollments,
    createFeatureEnrollment,
    createFeatureEnrollmentForPerpayPlus,
    fetchFeatureEnrollmentsForPerpayPlus,
    fetchActivePayments,
    fetchCardActivity,
    analyticsIterablePushNotificationOpen,
    navigateOnUrlPush,
    postAuthRedirect,
    fetchCardDashboardSummary,
    fetchMarketplaceDashboardSummary,
    fetchPlatformDashboardNotifications,
    actionsToNativeFromWeb,
    actionsToWebFromNative,
    fetchPerpayPlusDashboardSummary,
    fetchDirectDepositDashboardSummary,
    fetchLearnMore,
    launchSupportTool,
    hookActionEpic,
    partnerOnboardingSignup,
    verifyPartnerOnboardingCode,
    cleanupMagentoTokenOnLogout,
    cleanupAlgoliaOnLogout,
    partnerOnboardingSubmitCardApplication,
    fetchPartnerHostedCardApplication,
    partnerOnboardingPollApplicationStatus,
    fetchFeatureSwitch,

    // --

    cardAccountApplicationCrudDataModule.composedEpic,
    cardOnboardingSwitcherDataModule.composedEpic,
    cardManualPaymentSetupDataModule.composedEpic,
    cardStatementsDataModule.composedEpic,
    directDepositDetailsDataModule.composedEpic,
    ordersDataModule.composedEpic,
    cardPaymentSummaryDataModule.composedEpic,
    userInfoForCardOnboardingMVP2CrudDataModule.composedEpic,
    cardCreateAutoPayDataModule.composedEpic,
    bankAccountsForCardAutoPayDataModule.composedEpic,
    payrollInstructionsFlowDataModule.composedEpic,
    directDepositUpdateEmployerAndAmountDataModule.composedEpic,
    fetchCardDetailsV2DataModule.composedEpic,
    directDepositUpdateManualPaymentSetupDataModule.composedEpic,
    updateDirectDepositDataModule.composedEpic,
    perpayPlusDataModule.composedEpic,
    borrowerCreditsDataModule.composedEpic,
    marketplaceDetailsDataModule.composedEpic,
    featureEnrollmentsForPerpayPlusCrudDataModule.composedEpic,
    cardActivityDataModule.composedEpic,
    checkoutDataModule.composedEpic,
    dashboardV2DataModule.composedEpic,
    marketplaceDetailsNotificationsDataModule.composedEpic,
    cardDetailsNotificationsDataModule.composedEpic,
    pinwheelDataModule.composedEpic,
];

let epicManager = null;

export function getEpicManager() {
    if (!epicManager) {
        epicManager = new EpicManager(staticEpics);
    }

    return epicManager;
}
