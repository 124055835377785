import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    fetchCardAccount,
    replaceCardAccount,
    resetCardAccount,
    setCardAccountError,
} from '@perpay-web/fintech/actions/entities/cardAccounts';
import { ACTIVE } from '@perpay-web/fintech/constants/cardAccountStatuses';

const FetchCardAccountsDataModule = createDataModule({
    dataRequest: fetchCardAccount,
    dataSuccess: replaceCardAccount,
    dataError: setCardAccountError,
    dataReset: resetCardAccount,
});

const getRoot = (state) => state.dataModules.fetchCardAccounts;
const initialValue = { all: [] };

export const fetchCardAccountsDataModule = FetchCardAccountsDataModule({
    getRoot,
    initialValue,
});

// getData(state)[0] because this returns an array. only one
// card account should exist per customer.
const getCardAccount = (state) =>
    fetchCardAccountsDataModule.getData(state).all[0] || {};

export const getAvailableCredit = (state) =>
    getCardAccount(state).availableCredit || 0;

export const getCurrentBalance = (state) =>
    getCardAccount(state).currentBalance || 0;

export const getRemainingStatementBalance = (state) =>
    getCardAccount(state).remainingStatementBalance || 0;

export const getRemainingMinimumPaymentDue = (state) =>
    getCardAccount(state).remainingMinimumPaymentDue || 0;

export const getStatementDueDate = (state) =>
    getCardAccount(state).dueDateUtc || '';

const getMostRecentCard = (cards) => {
    if (!cards || cards.length === 0) {
        return null;
    }

    // sorts cards from created newest to oldest
    const sortedCards = cards
        .slice()
        .sort((card1, card2) => card2.created.localeCompare(card1.created));

    return sortedCards[0];
};

export const getCurrentLastFour = (state) => {
    const { cards } = getCardAccount(state);

    const card = getMostRecentCard(cards);

    if (card === null) {
        return '';
    }

    return card.lastFour;
};

export const getCurrentCardStatus = (state) => {
    const { cards } = getCardAccount(state);

    const card = getMostRecentCard(cards);

    if (card === null) {
        return '';
    }

    return card.status;
};

export const getHasCardAccount = (state) =>
    Boolean(fetchCardAccountsDataModule.getData(state).all.length);

export const getCreditLimit = (state) => getCardAccount(state).creditLimit || 0;

export const getCardAccountStatus = (state) =>
    getCardAccount(state).status || '';

export const getIsCardAccountActive = (state) =>
    getCardAccountStatus(state) === ACTIVE;
