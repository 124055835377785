import { useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';

/**
 * This is based on an example from the React Portals documentation.
 * https://reactjs.org/docs/portals.html
 */
export default function Portal({ children, tagName = 'div', parentId = '' }) {
    const portalElement = useMemo(
        () => document.createElement(tagName),
        [tagName],
    );

    useEffect(() => {
        const modalRoot = parentId
            ? document.getElementById(parentId)
            : document.body;
        if (modalRoot) {
            modalRoot.appendChild(portalElement);
        }

        return () => {
            if (modalRoot) {
                try {
                    modalRoot.removeChild(portalElement);
                } catch (unusedError) {
                    // if the portalElement isn't a child of the modalRoot,
                    // do nothing
                }
            }
        };
    }, [parentId, portalElement]);

    /**
     *         ___
     *     . -^   `--,
     *    /# =========`-_
     *   /# (--====___====\
     *  /#   .- --.  . --.|
     * /##   |  * ) (   * ),
     * |##   \    /\ \   / |
     * |###   ---   \ ---  |
     * |####      ___)    #|
     * |######           ##|
     *  \##### ---------- /
     *   \####           (
     *    `\###          |
     *      \###         |
     *       \##        |
     *        \###.    .)
     *         `======/
     *  SHOW ME WHAT YOU GOT
     */
    return createPortal(children, portalElement);
}
