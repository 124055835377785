import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    fetchCreditScoreHistory,
    fetchCreditScoreHistorySuccess,
    fetchCreditScoreHistoryError,
    fetchCreditScoreHistoryReset,
} from '@perpay-web/fintech/actions/entities/creditScoreHistory';

const FetchCreditScoreHistoryDataModule = createDataModule({
    dataRequest: fetchCreditScoreHistory,
    dataSuccess: fetchCreditScoreHistorySuccess,
    dataError: fetchCreditScoreHistoryError,
    dataReset: fetchCreditScoreHistoryReset,
});
const getRoot = (state) => state.dataModules.fetchCreditScoreHistory;
const initialValue = [];
const getScoreAndDate = (creditScoreDetail) => {
    const { scoreFactorCodes: unusedScoreFactorCodes, ...scoreAndDate } =
        creditScoreDetail;
    return scoreAndDate;
};
export const fetchCreditScoreHistoryDataModule =
    FetchCreditScoreHistoryDataModule({
        getRoot,
        initialValue,
    });
const getCreditScoreHistory = (state) => {
    const creditScores = fetchCreditScoreHistoryDataModule.getData(state);
    return creditScores
        ? creditScores.map((score) => getScoreAndDate(score)).reverse()
        : initialValue;
};
export const getLastFourScores = (state) => {
    const creditScores = getCreditScoreHistory(state);
    return creditScores.slice(Math.max(creditScores.length - 4, 0));
};

export const getCreditScoreHistoryPaginated = (state, itemsPerPage) => {
    const creditScores = fetchCreditScoreHistoryDataModule.getData(state);
    const newCreditScores = creditScores
        ? creditScores.map((score) => getScoreAndDate(score))
        : initialValue;

    const combinedArray = {};
    let combinedIndex = 1;

    for (let i = 0; i < newCreditScores.length; i += itemsPerPage) {
        combinedArray[combinedIndex] = newCreditScores.slice(
            i,
            i + itemsPerPage,
        );
        combinedIndex += 1;
    }

    return combinedArray;
};
