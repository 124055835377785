import { combineReducers } from '@reduxjs/toolkit';

import { referralStatsDataModule } from '@perpay-web/fintech/dataModules/referralStats';
import { generalAccountSummaryDataModule } from '@perpay-web/fintech/dataModules/generalAccountSummary';
import { fetchCardAccountApplicationDataModule } from '@perpay-web/fintech/dataModules/fetchCardAccountApplications';
import { createCardAccountApplicationsDataModule } from '@perpay-web/fintech/dataModules/createCardAccountApplications';
import { updateCardAccountApplicationDataModule } from '@perpay-web/fintech/dataModules/updateCardAccountApplications';
import { fetchCardAccountsDataModule } from '@perpay-web/fintech/dataModules/fetchCardAccounts';
import { fetchCardStatementsDataModule } from '@perpay-web/fintech/dataModules/fetchCardStatements';
import { fetchPaymentSetupSummaryDataModule } from '@perpay-web/fintech/dataModules/fetchPaymentSetupSummary';
import { cardManualPaymentSetupDataModule } from '@perpay-web/fintech/dataModules/composed/cardManualPaymentSetup';
import { fetchCardAccountApplicationForOnboardingDataModule } from '@perpay-web/fintech/dataModules/fetchCardAccountApplicationForOnboarding';
import { creditUnfreezeForCardAccountApplicationDataModule } from '@perpay-web/fintech/dataModules/creditUnfreezeCardAccountApplication';
import { cardAccountApplicationCrudDataModule } from '@perpay-web/fintech/dataModules/crud/cardAccountApplication';
import { fetchFeaturesForOnboardingDataModule } from '@perpay-web/fintech/dataModules/fetchFeaturesForOnboarding';
import { fetchCardActivationDataModule } from '@perpay-web/fintech/dataModules/fetchCardActivation';
import { gateCardAccountDataModule } from '@perpay-web/fintech/dataModules/gateCardAccount';
import { gateCardApplyDataModule } from '@perpay-web/fintech/dataModules/gateCardApply';
import { cardStatementsDataModule } from '@perpay-web/fintech/dataModules/composed/cardStatements';
import { downloadCardStatementDataModule } from '@perpay-web/fintech/dataModules/downloadCardStatement';
import { fetchDeductionsDataModule } from '@perpay-web/fintech/dataModules/fetchDeductions';
import { coreSummaryDataModule } from '@perpay-web/fintech/dataModules/coreSummary';
import { fetchUserStatusDataModule } from '@perpay-web/fintech/dataModules/fetchUserStatus';
import { ordersDataModule } from '@perpay-web/fintech/dataModules/composed/orders';
import { cardOnboardingSwitcherDataModule } from '@perpay-web/fintech/dataModules/composed/cardOnboardingSwitcher';
import { fetchUserInfoDataModule } from '@perpay-web/fintech/dataModules/fetchUserInfo';
import { updateUserInfoDataModule } from '@perpay-web/fintech/dataModules/updateUserInfo';
import { updateUserInfoForCardOnboardingMVP2DataModule } from '@perpay-web/fintech/dataModules/updateUserInfoForCardOnboardingMVP2';
import { userInfoForCardOnboardingMVP2CrudDataModule } from '@perpay-web/fintech/dataModules/crud/userInfoForCardOnboardingMVP2';
import { fetchPayrollInstructionsDataModule } from '@perpay-web/fintech/dataModules/fetchPayrollInstructions';
import { validateAddressForMVP2DataModule } from '@perpay-web/fintech/dataModules/validateAddressForMVP2';
import { uploadCardDocumentsDataModule } from '@perpay-web/fintech/dataModules/uploadCardDocuments';
import { fetchPinwheelABTestDataModule } from '@perpay-web/fintech/dataModules/fetchPinwheelABTest';
import { cardPaymentSummaryDataModule } from '@perpay-web/fintech/dataModules/composed/cardPaymentSummary';
import { fetchJobsDataModule } from '@perpay-web/fintech/dataModules/fetchJobs';
import { directDepositDetailsDataModule } from '@perpay-web/fintech/dataModules/composed/directDepositDetails';
import { fetchBankAccountsDataModule } from '@perpay-web/fintech/dataModules/fetchBankAccounts';
import { cardCreateAutoPayDataModule } from '@perpay-web/fintech/dataModules/composed/cardCreateAutoPay';
import { createBankAccountDataModule } from '@perpay-web/fintech/dataModules/createBankAccount';
import { createBankAccountForCardAutoPayDataModule } from '@perpay-web/fintech/dataModules/createBankAccountForCardAutoPay';
import { fetchBankAccountsForCardAutoPayDataModule } from '@perpay-web/fintech/dataModules/fetchBankAccountsForCardAutoPay';
import { bankAccountsForCardAutoPayDataModule } from '@perpay-web/fintech/dataModules/crud/bankAccountsForCardAutoPay';
import { createAutoPayScheduleDataModule } from '@perpay-web/fintech/dataModules/createAutoPaySchedule';
import { createAutoPayScheduleForCardAutoPayDataModule } from '@perpay-web/fintech/dataModules/createAutoPayScheduleForCardAutoPay';
import { payrollInstructionsFlowDataModule } from '@perpay-web/fintech/dataModules/composed/payrollInstructionsFlow';
import { cancelAutoPayScheduleDataModule } from '@perpay-web/fintech/dataModules/cancelAutoPaySchedule';
import { updateAutoPayScheduleDataModule } from '@perpay-web/fintech/dataModules/updateAutoPaySchedule';
import { fetchAutoPaySchedulesDataModule } from '@perpay-web/fintech/dataModules/fetchAutoPaySchedules';
import { fetchInitiatedAutoPayPaymentsDataModule } from '@perpay-web/fintech/dataModules/fetchInitiatedAutoPayPayments';
import { directDepositUpdateEmployerAndAmountDataModule } from '@perpay-web/fintech/dataModules/composed/directDepositUpdateEmployerAndAmount';
import { fetchCardDetailsV2DataModule } from '@perpay-web/fintech/dataModules/composed/fetchCardDetailsV2';
import { directDepositUpdateManualPaymentSetupDataModule } from '@perpay-web/fintech/dataModules/composed/directDepositUpdateManualPaymentSetup';
import { createPrimaryJobDataModule } from '@perpay-web/fintech/dataModules/createPrimaryJob';
import { updateJobDataModule } from '@perpay-web/fintech/dataModules/updateJob';
import { updateDirectDepositDataModule } from '@perpay-web/fintech/dataModules/composed/updateDirectDeposit';
import { fetchBorrowerCreditSummaryDataModule } from '@perpay-web/fintech/dataModules/fetchBorrowerCreditSummary';
import { fetchBorrowerCreditsDataModule } from '@perpay-web/fintech/dataModules/fetchBorrowerCredits';
import { borrowerCreditsDataModule } from '@perpay-web/fintech/dataModules/composed/borrowerCredits';
import { fetchFeatureEnrollmentsDataModule } from '@perpay-web/fintech/dataModules/fetchFeatureEnrollments';
import { createFeatureEnrollmentDataModule } from '@perpay-web/fintech/dataModules/createFeatureEnrollment';
import { createFeatureEnrollmentForPerpayPlusDataModule } from '@perpay-web/fintech/dataModules/createFeatureEnrollmentForPerpayPlus';
import { fetchFeatureEnrollmentsForPerpayPlusDataModule } from '@perpay-web/fintech/dataModules/fetchFeatureEnrollmentsForPerpayPlus';
import { perpayPlusDataModule } from '@perpay-web/fintech/dataModules/composed/perpayPlus';
import { fetchActivePaymentsDataModule } from '@perpay-web/fintech/dataModules/fetchActivePayments';
import { fetchABTestsDataModule } from '@perpay-web/fintech/dataModules/fetchABTests';
import { fetchFeatureSwitchesDataModule } from '@perpay-web/fintech/dataModules/fetchFeatureSwitches';
import { marketplaceDetailsDataModule } from '@perpay-web/fintech/dataModules/composed/marketplaceDetails';
import { featureEnrollmentsForPerpayPlusCrudDataModule } from '@perpay-web/fintech/dataModules/crud/featureEnrollmentsForPerpayPlus';
import { fetchCardActivityDataModule } from '@perpay-web/fintech/dataModules/fetchCardActivity';
import { cardActivityDataModule } from '@perpay-web/fintech/dataModules/composed/cardActivity';
import { fetchCreditLimitsDataModule } from '@perpay-web/fintech/dataModules/fetchCreditLimits';
import { updateCreditLimitDataModule } from '@perpay-web/fintech/dataModules/updateCreditLimit';
import { acceptCreditLimitDataModule } from '@perpay-web/fintech/dataModules/acceptCreditLimit';
import { setStepsForUpdateDirectDepositDataModule } from '@perpay-web/fintech/dataModules/setStepsForUpdateDirectDeposit';
import { fetchTransactionDetailsDataModule } from '@perpay-web/fintech/dataModules/fetchTransactionDetails';
import { checkoutDataModule } from '@perpay-web/fintech/dataModules/composed/checkout';
import { fetchCardDashboardSummaryDataModule } from '@perpay-web/fintech/dataModules/fetchCardDashboardSummary';
import { fetchMarketplaceDashboardSummaryDataModule } from '@perpay-web/fintech/dataModules/fetchMarketplaceDashboardSummary';
import { fetchPlatformDashboardNotificationsDataModule } from '@perpay-web/fintech/dataModules/fetchPlatformDashboardNotifications';
import { fetchPerpayPlusDashboardSummaryDataModule } from '@perpay-web/fintech/dataModules/fetchPerpayPlusDashboardSummary';
import { fetchDirectDepositDashboardSummaryDataModule } from '@perpay-web/fintech/dataModules/fetchDirectDepositDashboardSummary';
import { dashboardDataModule as dashboardV2DataModule } from '@perpay-web/fintech/dataModules/composed/dashboardV2';
import { fetchOrdersDataModule } from '@perpay-web/fintech/dataModules/fetchOrders';
import { fetchLearnMoreDataModule } from '@perpay-web/fintech/dataModules/fetchLearnMore';
import { fetchWithdrawalBalanceV2DataModule } from '@perpay-web/fintech/dataModules/fetchWithdrawalBalance';
import { pinwheelDataModule } from '@perpay-web/fintech/dataModules/composed/pinwheel';
import { fetchCreditScoreHistoryDataModule } from '@perpay-web/fintech/dataModules/fetchCreditScoreHistory';
import { fetchAddressSuggestionsDataModule } from '@perpay-web/fintech/dataModules/fetchAddressSuggestions';
import { fetchAddressDetailsDataModule } from '@perpay-web/fintech/dataModules/fetchAddressDetails';
import { partnerOnboardingSignupDataModule } from '@perpay-web/fintech/dataModules/partnerOnboardingSignup';
import { createPasswordDataModule } from '@perpay-web/fintech/dataModules/partnerOnboardingCreatePassword';
import { partnerOnboardingSubmitCardApplicationDataModule } from '@perpay-web/fintech/dataModules/partnerOnboardingSubmitCardApplication';
import { partnerHostedCardApplicationDataModule } from '@perpay-web/fintech/dataModules/partnerHostedCardApplication';

export default combineReducers({
    fetchCardAccounts: fetchCardAccountsDataModule.reducer,
    createCardAccountApplications:
        createCardAccountApplicationsDataModule.reducer,
    generalAccountSummary: generalAccountSummaryDataModule.reducer,
    referralStats: referralStatsDataModule.reducer,
    updateCardAccountApplication:
        updateCardAccountApplicationDataModule.reducer,
    fetchCardAccountApplication: fetchCardAccountApplicationDataModule.reducer,
    fetchCardStatements: fetchCardStatementsDataModule.reducer,
    fetchPaymentSetupSummary: fetchPaymentSetupSummaryDataModule.reducer,
    cardManualPaymentSetup: cardManualPaymentSetupDataModule.reducer,
    directDepositDetails: directDepositDetailsDataModule.reducer,
    fetchCardAccountApplicationForOnboarding:
        fetchCardAccountApplicationForOnboardingDataModule.reducer,
    cardAccountApplicationCrud: cardAccountApplicationCrudDataModule.reducer,
    fetchFeaturesForOnboarding: fetchFeaturesForOnboardingDataModule.reducer,
    fetchCardActivation: fetchCardActivationDataModule.reducer,
    gateCardAccount: gateCardAccountDataModule.reducer,
    gateCardApply: gateCardApplyDataModule.reducer,
    cardStatements: cardStatementsDataModule.reducer,
    downloadCardStatement: downloadCardStatementDataModule.reducer,
    fetchDeductions: fetchDeductionsDataModule.reducer,
    coreSummary: coreSummaryDataModule.reducer,
    fetchUserStatus: fetchUserStatusDataModule.reducer,
    orders: ordersDataModule.reducer,
    creditUnfreezeForCardAccountApplication:
        creditUnfreezeForCardAccountApplicationDataModule.reducer,
    cardOnboardingSwitcher: cardOnboardingSwitcherDataModule.reducer,
    fetchUserInfo: fetchUserInfoDataModule.reducer,
    updateUserInfo: updateUserInfoDataModule.reducer,
    updateUserInfoForCardOnboardingMVP2:
        updateUserInfoForCardOnboardingMVP2DataModule.reducer,
    userInfoForCardOnboardingMVP2Crud:
        userInfoForCardOnboardingMVP2CrudDataModule.reducer,
    fetchPayrollInstructions: fetchPayrollInstructionsDataModule.reducer,
    validateAddressForMVP2: validateAddressForMVP2DataModule.reducer,
    uploadCardDocuments: uploadCardDocumentsDataModule.reducer,
    fetchPinwheelABTest: fetchPinwheelABTestDataModule.reducer,
    fetchJobs: fetchJobsDataModule.reducer,
    cardPaymentSummary: cardPaymentSummaryDataModule.reducer,
    fetchBankAccounts: fetchBankAccountsDataModule.reducer,
    cardCreateAutoPay: cardCreateAutoPayDataModule.reducer,
    createBankAccount: createBankAccountDataModule.reducer,
    createBankAccountForCardAutoPay:
        createBankAccountForCardAutoPayDataModule.reducer,
    fetchBankAccountsForCardAutoPay:
        fetchBankAccountsForCardAutoPayDataModule.reducer,
    bankAccountsForCardAutoPay: bankAccountsForCardAutoPayDataModule.reducer,
    createAutoPaySchedule: createAutoPayScheduleDataModule.reducer,
    createAutoPayScheduleForCardAutoPay:
        createAutoPayScheduleForCardAutoPayDataModule.reducer,
    payrollInstructionsFlow: payrollInstructionsFlowDataModule.reducer,
    cancelAutoPaySchedule: cancelAutoPayScheduleDataModule.reducer,
    fetchAutoPaySchedules: fetchAutoPaySchedulesDataModule.reducer,
    fetchInitiatedAutoPayPayments:
        fetchInitiatedAutoPayPaymentsDataModule.reducer,
    updateAutoPaySchedule: updateAutoPayScheduleDataModule.reducer,
    directDepositUpdateEmployerAndAmount:
        directDepositUpdateEmployerAndAmountDataModule.reducer,
    fetchCardDetailsV2: fetchCardDetailsV2DataModule.reducer,
    directDepositUpdateManualPaymentSetup:
        directDepositUpdateManualPaymentSetupDataModule.reducer,
    createPrimaryJob: createPrimaryJobDataModule.reducer,
    updateJob: updateJobDataModule.reducer,
    updateDirectDeposit: updateDirectDepositDataModule.reducer,
    fetchBorrowerCreditSummary: fetchBorrowerCreditSummaryDataModule.reducer,
    fetchBorrowerCredits: fetchBorrowerCreditsDataModule.reducer,
    borrowerCredits: borrowerCreditsDataModule.reducer,
    fetchFeatureEnrollments: fetchFeatureEnrollmentsDataModule.reducer,
    createFeatureEnrollment: createFeatureEnrollmentDataModule.reducer,
    perpayPlus: perpayPlusDataModule.reducer,
    fetchFeatureEnrollmentsForPerpayPlus:
        fetchFeatureEnrollmentsForPerpayPlusDataModule.reducer,
    createFeatureEnrollmentForPerpayPlus:
        createFeatureEnrollmentForPerpayPlusDataModule.reducer,
    fetchActivePayments: fetchActivePaymentsDataModule.reducer,
    fetchABTests: fetchABTestsDataModule.reducer,
    fetchFeatureSwitches: fetchFeatureSwitchesDataModule.reducer,
    marketplaceDetails: marketplaceDetailsDataModule.reducer,
    featureEnrollmentsForPerpayPlus:
        featureEnrollmentsForPerpayPlusCrudDataModule.reducer,
    fetchCardActivity: fetchCardActivityDataModule.reducer,
    cardActivity: cardActivityDataModule.reducer,
    fetchCreditLimits: fetchCreditLimitsDataModule.reducer,
    updateCreditLimit: updateCreditLimitDataModule.reducer,
    acceptCreditLimit: acceptCreditLimitDataModule.reducer,
    setStepsForUpdateDirectDeposit:
        setStepsForUpdateDirectDepositDataModule.reducer,
    fetchTransactionDetails: fetchTransactionDetailsDataModule.reducer,
    checkout: checkoutDataModule.reducer,
    fetchCardDashboardSummary: fetchCardDashboardSummaryDataModule.reducer,
    fetchMarketplaceDashboardSummary:
        fetchMarketplaceDashboardSummaryDataModule.reducer,
    fetchPlatformDashboardNotifications:
        fetchPlatformDashboardNotificationsDataModule.reducer,
    fetchPerpayPlusDashboardSummary:
        fetchPerpayPlusDashboardSummaryDataModule.reducer,
    fetchDirectDepositDashboardSummary:
        fetchDirectDepositDashboardSummaryDataModule.reducer,
    dashboardV2: dashboardV2DataModule.reducer,
    fetchOrders: fetchOrdersDataModule.reducer,
    fetchLearnMore: fetchLearnMoreDataModule.reducer,
    fetchWithdrawalBalanceV2: fetchWithdrawalBalanceV2DataModule.reducer,
    pinwheel: pinwheelDataModule.reducer,
    fetchCreditScoreHistory: fetchCreditScoreHistoryDataModule.reducer,
    fetchAddressSuggestions: fetchAddressSuggestionsDataModule.reducer,
    fetchAddressDetails: fetchAddressDetailsDataModule.reducer,
    partnerOnboardingSignup: partnerOnboardingSignupDataModule.reducer,
    createPassword: createPasswordDataModule.reducer,
    partnerOnboardingSubmitCardApplication:
        partnerOnboardingSubmitCardApplicationDataModule.reducer,
    partnerHostedCardApplication:
        partnerHostedCardApplicationDataModule.reducer,
});
