import { lazy } from 'react';
import { withFullscreenLoader } from '@perpay-web/components/higher-order/WithLoader/WithLoader';
import { importStorefront } from '@perpay-web/fintech/utils/imports/importStorefront';
import { getEpicManager } from '@perpay-web/fintech/epics/epics';
import { getReducerManager } from '@perpay-web/fintech/reducers/reducers';
import { paths as storefrontPaths } from '@perpay-web/storefront/props/appPaths';
import { SHOP_PREFIX } from '@perpay-web/constants/paths';
import MagentoRedirect from '@perpay-web/fintech/components/composite/MagentoRedirect/MagentoRedirect';
import AppContainer from '@perpay-web/fintech/components/containers/AppContainer';
import { RedirectFallbackContainer } from '@perpay-web/fintech/components/containers/RedirectFallbackContainer';
import ProductQueryRedirect from '@perpay-web/components/base/ProductQueryRedirect/ProductQueryRedirect';
import { authenticatedRoutes } from './AuthenticatedRoutes';
import { authenticatingRoutes } from './AuthenticatingRoutes';
import { unauthenticatedRoutes } from './UnauthenticatedRoutes';

const LazyStorefrontApp = withFullscreenLoader(
    lazy(() => {
        const importPromise = importStorefront();

        importPromise.then(({ StorefrontUnit }) => {
            StorefrontUnit(getEpicManager(), getReducerManager());
        });

        return importPromise;
    }),
);

/**
 * @type {import('react-router-dom').DataRouteObject[]}
 */
const magentoRedirectRoutes = Object.values(storefrontPaths)
    .filter((path) => Boolean(path.magentoPath))
    .map((path) => ({
        path: `${SHOP_PREFIX}${path.magentoPath}`,
        element: (
            <MagentoRedirect
                storefrontPath={path.path}
                magentoRewrite={path.magentoRewrite}
            />
        ),
    }));

/**
 * @type {import('react-router-dom').DataRouteObject[]}
 */
export const appRoutes = [
    { path: '/shop/*', element: <LazyStorefrontApp /> },
    {
        path: '/',
        element: <AppContainer />,
        children: [
            ...authenticatedRoutes,
            ...authenticatingRoutes,
            ...unauthenticatedRoutes,
            ...magentoRedirectRoutes,
            {
                path: storefrontPaths.productDetailsRoot.path,
                element: <ProductQueryRedirect />,
            },
            { path: '', element: <RedirectFallbackContainer /> },
        ],
    },
    { path: '*', element: <RedirectFallbackContainer /> },
];
