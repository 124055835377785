import { createDataModuleActions } from '@perpay-web/data-module/createDataModuleActions';
import { createAction } from '@reduxjs/toolkit';

const partnerHostedCardOnboardingActions = createDataModuleActions(
    'PARTNER_HOSTED_CARD_APPLICATION',
);
export const fetchPartnerHostedCardApplication =
    partnerHostedCardOnboardingActions.dataRequest;
export const fetchPartnerHostedCardApplicationSuccess =
    partnerHostedCardOnboardingActions.dataSuccess;
export const fetchPartnerHostedCardApplicationError =
    partnerHostedCardOnboardingActions.dataError;
export const fetchPartnerHostedCardApplicationReset =
    partnerHostedCardOnboardingActions.dataReset;

export const partnerHostedApplicationStartPolling = createAction(
    'backend/PARTNER_HOSTED_APPLICATION::POLLING::START',
);
export const partnerHostedApplicationPollingComplete = createAction(
    'backend/PARTNER_HOSTED_APPLICATION::POLLING::STOP',
);
export const partnerHostedApplicationCancelPolling = createAction(
    'backend/PARTNER_HOSTED_APPLICATION::POLLING::CANCEL',
);
export const partnerHostedApplicationPollingError = createAction(
    'backend/PARTNER_HOSTED_APPLICATION::POLLING::ERROR',
);
