import { SHOP_LAST_PAGE_COOKIE } from '@perpay-web/constants/cookies';
import { pathIsInternalToApp } from '@perpay-web/utils/routeUtils';
import { getCookie, expireCookie } from '@perpay-web/utils/cookieUtils';
import { isAndroidAgent } from '@perpay-web/utils/userAgentUtils';
import { TRACKING_HOST } from '@perpay-web/fintech/constants/urls';
import { HOST, MAGENTO_HOST, PROD_HOST } from '@perpay-web/constants/urls';
import { paths } from '@perpay-web/fintech/props/appPaths';

export function compareURLHosts(originalUrl, comparativeUrl) {
    try {
        const a = new URL(originalUrl);
        const b = new URL(comparativeUrl);
        return a.host === b.host;
    } catch (unusedError) {
        return false;
    }
}

// We use a whitelist so that other sites can't create redirect links from our login
// to an evil site for phishing attacks against our users.
// e.g. https://app.perpay.com/login/?next=https://app.perpayevil.com/upload-your-paystub
const EXTERNAL_HOST_WHITELIST = [HOST, MAGENTO_HOST, TRACKING_HOST];

export function pathIsWhitelistedExternal(path) {
    if (pathIsInternalToApp(path)) {
        return false;
    }

    try {
        const parsed = new URL(path); // throws exception if URL is malformed
        return EXTERNAL_HOST_WHITELIST.includes(parsed.host);
    } catch (unusedError) {
        return false;
    }
}

export function getShopLastPageCookie() {
    const shopLastPageCookie = getCookie(SHOP_LAST_PAGE_COOKIE);
    return shopLastPageCookie;
}

export function getAndExpireShopLastPageCookie() {
    const shopLastPageCookie = getCookie(SHOP_LAST_PAGE_COOKIE);
    expireCookie(PROD_HOST, SHOP_LAST_PAGE_COOKIE);
    return shopLastPageCookie;
}

export function getNextParam() {
    const params = new URLSearchParams(window.location.search);
    const nextPath = params.get('next');
    return nextPath;
}

export function generateSmsTemplateHref(message) {
    const separator = isAndroidAgent() ? '?' : '&';
    return `sms:${separator}body=${encodeURIComponent(message)}`;
}

export function generateEmailTemplateHref(message, subject) {
    return `mailto:?subject=${encodeURIComponent(
        subject,
    )}&body=${encodeURIComponent(message)}`;
}

export function generatePhoneTemplateHref(number) {
    return `tel:${number}`;
}

// Capture any non-slash characters at the end of a string that may or may not end in a slash
const codeRe = /([^/]+)\/?$/;
export function getPathEnd(path) {
    const result = codeRe.exec(path);
    if (!result) {
        return '';
    }

    // Get the capture group
    return result[1];
}

const appendTrailingSlash = (path) => `${path}/`;

export const isUnauthenticatedPath = (path) => {
    let matcher = path;
    if (!matcher.endsWith('/')) {
        matcher = appendTrailingSlash(matcher);
    }

    if (matcher.startsWith(paths.referrals.path)) {
        const hasReferralCode =
            matcher.replace(paths.referrals.path, '').length > 0;
        return hasReferralCode;
    }

    const matchesUnauthenticatedPaths = [
        paths.login.path,
        paths.logout.path,
        paths.signUp.path,
    ].includes(matcher);

    return matchesUnauthenticatedPaths;
};
