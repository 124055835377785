import { getClassName } from '@perpay-web/utils/domUtils';

import styles from './CardCta.scss';

const CardCta = ({
    Component,
    loading = false,
    mode = 'dark',
    className = '',
    ...rest
}) => (
    <Component
        className={getClassName(
            styles.button,
            styles[`button--${mode}`],
            loading ? styles[`button--loading--${mode}`] : '',
            className,
        )}
        action='primary'
        loading={loading}
        {...rest}
    />
);

export default CardCta;
