import {
    UPDATE_USER_INFO,
    REPLACE_USER_INFO,
    UPDATE_USER_INFO_ERROR,
} from '@perpay-web/fintech/constants/actionTypes';

const initialState = {
    submitting: false,
};

const editUserInfoReducer = (state = initialState, action = undefined) => {
    const { type } = action;

    switch (type) {
        case UPDATE_USER_INFO_ERROR:
            return { ...state, submitting: false };
        case REPLACE_USER_INFO:
            return { ...state, submitting: false };
        case UPDATE_USER_INFO:
            return { ...state, submitting: true };
        default:
            return state;
    }
};

export default editUserInfoReducer;
