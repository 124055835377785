import { getOrCreateRouter } from '@perpay-web/services/router';
import { analyticsLocationChange } from '@perpay-web/hooks/useTrackLocationChange';
import store from '@perpay-web/fintech/settings/configureStore';
import {
    clearLastReactStorefrontPage,
    pathIsInReactStorefront,
    setLastReactStorefrontPage,
} from '@perpay-web/utils/routeUtils';
import { appRoutes } from './AppRoutes';

const createRouter = () =>
    getOrCreateRouter(
        appRoutes,
        // On initialization, dispatch location change once
        (router) => {
            store.dispatch(
                analyticsLocationChange({
                    location: router.state.location,
                    action: 'POP',
                }),
            );
            clearLastReactStorefrontPage();
            if (pathIsInReactStorefront(router.state.location.pathname))
                setLastReactStorefrontPage(
                    router.state.location.pathname,
                    router.state.location.search,
                );
        },
    );

// imported directly in the root file
export default createRouter();
