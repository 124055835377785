export const NEW = 'new';
export const APPROVED = 'approved';
export const REJECTED = 'rejected';
export const IN_ERROR = 'in_error';
export const CLARITY_FROZEN = 'clarity_frozen';
export const AWAITING_DEDUCTION_SETUP = 'awaiting_deduction_setup';
export const COMPLETE = 'complete';
export const UPLOAD_DOCUMENTS = 'upload_documents';
export const IN_REVIEW = 'in_review';

// Intermediate statuses which should be handled during polling
const PREFERENCES = 'preferences';
const IN_PROGRESS = 'in_progress';
const CAPTURE_DATA = 'capture_data';
const CREDIT_DECISION = 'credit_decision';
const CREDIT_PULL_CONSENT = 'credit_pull_consent';

export const CONTINUE_POLLING_STATUSES = [
    NEW,
    PREFERENCES,
    IN_PROGRESS,
    IN_REVIEW,
    CAPTURE_DATA,
    CREDIT_DECISION,
    CREDIT_PULL_CONSENT,
];
