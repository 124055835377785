import {
    cardStatementsDownloadError,
    cardStatementsDownloadSuccess,
    downloadCardStatement,
    resetCardStatementsDownload,
} from '@perpay-web/fintech/actions/entities/cardStatements';

const initialState = {
    submittingUuid: '',
};

const cardStatementsReducer = (state = initialState, action = undefined) => {
    switch (action.type) {
        case downloadCardStatement().type:
            return { ...state, submittingUuid: action.payload.uuid };
        case cardStatementsDownloadSuccess().type:
        case resetCardStatementsDownload().type:
        case cardStatementsDownloadError().type:
            return { ...state, submittingUuid: '' };
        default:
            return state;
    }
};

export default cardStatementsReducer;
