import {
    BACKEND_FETCH_FEATURE_ENROLLMENTS,
    BACKEND_FEATURES_ENROLL,
    BACKEND_FEATURES_REENABLE,
    BACKEND_FEATURES_DISABLE,
    STORE_FEATURES_ENROLL_SUCCESS,
    STORE_FEATURES_ENROLL_ERROR,
    STORE_REPLACE_FEATURE_ENROLLMENTS,
    STORE_FETCH_FEATURE_ENROLLMENTS_ERROR,
} from '@perpay-web/fintech/constants/actionTypes';

const initialState = {
    isLoading: true,
    isSubmitting: false,
    errors: {},
};

const cardSettingsReducer = (state = initialState, action = undefined) => {
    switch (action.type) {
        case BACKEND_FETCH_FEATURE_ENROLLMENTS: {
            return { ...state, isLoading: true };
        }
        case BACKEND_FEATURES_ENROLL:
        case BACKEND_FEATURES_REENABLE:
        case BACKEND_FEATURES_DISABLE:
            return { ...state, isSubmitting: true, errors: {} };
        case STORE_FEATURES_ENROLL_SUCCESS:
            return { ...state, isSubmitting: false };
        case STORE_FEATURES_ENROLL_ERROR: {
            return { ...state, isSubmitting: false, errors: action.payload };
        }
        case STORE_REPLACE_FEATURE_ENROLLMENTS:
            return { ...state, isLoading: false };
        case STORE_FETCH_FEATURE_ENROLLMENTS_ERROR:
            return { ...state, isLoading: false, errors: action.payload };
        default:
            return state;
    }
};

export default cardSettingsReducer;
