import {
    createWithdrawalRequest,
    createWithdrawalRequestErrors,
    createWithdrawalRequestSuccess,
    fetchWithdrawalBalance,
    replaceWithdrawalBalance,
    withdrawalBalanceError,
} from '@perpay-web/fintech/actions/ui/withdrawal';

const initialState = {
    availableCredit: 0,
    errors: {},
    isLoading: false,
    noActiveOrders: true,
    profileComplete: true,
    validBankAccountExists: true,
};

const withdrawalBalanceReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;
    switch (type) {
        case createWithdrawalRequest().type:
            return { ...state, submitting: true, errors: {} };
        case createWithdrawalRequestSuccess().type:
            return { ...state, submitting: false, ...payload };
        case createWithdrawalRequestErrors().type:
            return { ...state, submitting: false, errors: payload };
        case fetchWithdrawalBalance().type:
            return { ...state, isLoading: true };
        case withdrawalBalanceError().type:
            return { ...state, isLoading: false, errors: payload };
        case replaceWithdrawalBalance().type:
            return {
                ...state,
                isLoading: false,
                validBankAccountExists: payload.validBankAccountExists,
                noActiveOrders: payload.noActiveOrders,
                availableCredit: payload.availableCredit,
                profileComplete: payload.profileComplete,
            };
        default:
            return state;
    }
};

export default withdrawalBalanceReducer;
