import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    closeSupportTool,
    launchSupportTool,
    loadedSupportTool,
    analyticsSelfHelpOpened,
    getSupportToolIntercomBucket,
    getSupportToolInternallyOpened,
    getSupportToolOpen,
} from '@perpay-web/services/supportTool';
import CustomLink from '@perpay-web/fintech/components/base/CustomLink/CustomLink';
import {
    getSignupRequirements,
    getSignupStep,
} from '@perpay-web/fintech/selectors/ui/signup';

import SupportTool from '@perpay-web/components/base/SupportTool/SupportTool';
import { VERIFIED_PHONE } from '@perpay-web/fintech/constants/steps/signupSteps';
import { getIsAuthenticated } from '@perpay-web/fintech/selectors/authentication';

const SupportToolContainer = ({ className }) => {
    const dispatch = useDispatch();
    const intercomBucket = useSelector(getSupportToolIntercomBucket);
    const internallyOpened = useSelector(getSupportToolInternallyOpened);
    const isAuthenticated = useSelector(getIsAuthenticated);
    const isInSignupFlow = useSelector(getSignupRequirements).length > 0;
    const isOnSignupStepPhone = useSelector(getSignupStep) === VERIFIED_PHONE;
    const visible = isAuthenticated && (!isInSignupFlow || isOnSignupStepPhone);
    const opened = useSelector(getSupportToolOpen);

    const closeHandler = useCallback(
        () => dispatch(closeSupportTool()),
        [dispatch],
    );
    const openHandler = useCallback(
        (newInternallyOpened, newIntercomBucket) =>
            dispatch(launchSupportTool(newInternallyOpened, newIntercomBucket)),
        [dispatch],
    );

    const onLoad = useCallback(() => dispatch(loadedSupportTool()), [dispatch]);
    const onOpen = useCallback(
        () => dispatch(analyticsSelfHelpOpened()),
        [dispatch],
    );

    return (
        <SupportTool
            className={className}
            intercomBucket={intercomBucket}
            internallyOpened={internallyOpened}
            isAuthenticated={isAuthenticated}
            hidden={!visible}
            opened={opened}
            closeHandler={closeHandler}
            openHandler={openHandler}
            onLoad={onLoad}
            onOpen={onOpen}
            LinkComponent={CustomLink}
        />
    );
};

export default SupportToolContainer;
