import { useRef, useEffect, memo } from 'react';
import lottie from 'lottie-web';

/**
 * A simple Lottie Wrapper for loading animations
 *
 * https://github.com/chenqingspring/react-lottie/issues/139
 */
const Lottie = ({
    options,
    width = 'auto',
    height = 'auto',
    ariaRole = 'button',
    ariaLabel = 'animation',
    speed = 1,
    style = {},
}) => {
    const elementRef = useRef(null);
    const lottieInstanceRef = useRef();

    useEffect(() => {
        if (elementRef.current) {
            lottieInstanceRef.current = lottie.loadAnimation({
                ...options,
                container: elementRef.current,
            });
            if (speed) lottieInstanceRef.current.setSpeed(speed);
        }
        return () => {
            if (lottieInstanceRef.current) {
                lottieInstanceRef.current.destroy();
                lottieInstanceRef.current = null;
            }
        };
    }, [options, speed]);

    return (
        <div
            aria-label={ariaLabel}
            role={ariaRole}
            style={{
                width,
                height,
                overflow: 'hidden',
                margin: '0 auto',
                outline: 'none',
                ...style,
            }}
            ref={elementRef}
        />
    );
};

export default memo(Lottie);
