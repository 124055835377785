import { useState } from 'react';
import { createContextProvider } from '@perpay-web/utils/componentUtils';

const usePartnerHosted = () => {
    const [showDetailedForm, setShowDetailedForm] = useState(false);
    const [showPhoneDisclosure, setShowPhoneDisclosure] = useState(false);

    return {
        showDetailedForm,
        setShowDetailedForm,
        showPhoneDisclosure,
        setShowPhoneDisclosure,
    };
};

const providerFactory = (Context) => {
    const Provider = ({ children, storyOverrideValue }) => {
        const hook = usePartnerHosted();

        return (
            <Context.Provider value={storyOverrideValue || hook}>
                {children}
            </Context.Provider>
        );
    };

    return Provider;
};

export const [PartnerHostedProvider, usePartnerHostedContext] =
    createContextProvider(providerFactory);
