import { useCallback } from 'react';
import { createReducer } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { getInitialState } from '@perpay-web/utils/reducerUtils';
import { noop } from '@perpay-web/utils/noop';

import { useMountAndUnmount } from './useMountAndUnmount';

/**
 * This file allows us to use a step-setting pattern both using an imperative
 * function call within a component, or via dispatching actions.
 *
 * This module exposes:
 * 1. A higher-order reducer to easily integrate with a component's reducer
 * 2. Action types to use in epics
 * 3. Action creators, to dispatch actions e.g. from within a container, hook, or in an epic.
 * 4. A hook, to allow components to query their current step and imperatively set their step.
 *
 * LIMITATIONS:
 * 1. Only one `withReduxStepsReducer` can be used per-reducer. (The state keys conflict)
 *
 * Sample usage:
 *
 * ```
 * // constants/steps/myComponentSteps.js
 * export const START_STEP = 'start';
 * export const INFO_STEP = 'info';
 * export const SUCCESS_STEP = 'success';
 * export const ERROR_STEP = 'error';
 * export const MY_COMPONENT_STEPS = [ START_STEP, INFO_STEP, SUCCESS_STEP, ERROR_STEP ];
 * ```
 *
 * ```
 * // reducers/ui/myComponent.js
 * import { STEPS } from './constants'
 * const myReducer = (state = initialState, action = {}) => { ... }
 *
 * export default withReduxStepsReducer(STEPS, myReducer);
 * ```
 *
 * ```
 * // selectors/ui/myComponent.js
 * export const getRoot = state => state.ui.myComponent;
 * ```
 *
 * ```
 * // epics/myComponent.js
 * export const fetchMyComponentData = (action$, state$, { get }) =>
 *   action$.pipe(
 *     ofType(MY_COMPONENT_DATA_FETCH),
 *     switchMap(() => get('api/foo')),
 *     mergeMap(() => [reduxStepsSetStep(STEPS, SUCCESS_STEP)]),
 *     handleError(() => reduxStepsSetStep(STEPS, ERROR_STEP)),
 *   );
 * ```
 *
 * ```
 * // MyComponent.js
 * import { getRoot } from './selectors.js';
 *
 * const MyComponent = ({
 *   fetchData,
 * }) => {
 *   const { step, setStep, goBack } = useReduxSteps(STEPS, START_STEP, { rootSelector: getRoot });
 *
 *   return (
 *     <React.Fragment>
 *       <Step
 *         name={START_STEP}
 *         current={step}
 *         render={() => (
 *           <div>
 *             You are on the start step
 *             <CustomButton onClick={fetchData}>Activate</CustomButton>
 *             <CustomButton onClick={() => setStep(INFO_STEP)}>More information</CustomButton>
 *           </div>
 *         )}
 *       />
 *      <Step
 *         name={INFO_STEP}
 *         current={step}
 *         render={() => (
 *           <div>
 *             You are on the info step
 *             <CustomButton onClick={goBack}>Go back</CustomButton>
 *           </div>
 *         )}
 *       />
 *      <Step
 *         name={ERROR_STEP}
 *         current={step}
 *         render={() => (
 *           <div>
 *             You are on the info step
 *             <CustomButton onClick={goBack}>Go back</CustomButton>
 *           </div>
 *         )}
 *       />
 *      <Step
 *         name={SUCCESS_STEP}
 *         current={step}
 *         render={() => (
 *           <div>
 *             Success!
 *           </div>
 *         )}
 *       />
 *     </React.Fragment>
 *   );
 * }
 * ```
 */

const getInitialStack = (steps, initialStep) => {
    const initialStepIndex = steps.indexOf(initialStep);

    if (initialStepIndex === -1) {
        throw new Error(`Step ${initialStep} not found in ${steps}`);
    }

    return steps.slice(0, initialStepIndex + 1).reverse(); // include the initial step in the slice
};

/**
 * Get a unique-ish string for each instance of useReduxSteps steps.
 * This is needed because redux actions are emitted globally,
 * so all reducers will receive any component's set-step action.
 * We need a way to reference a component's set-step individually.
 * This function provides that key
 */
export const reduxStepsGetKey = (steps) => `${steps}`;

const getReduxStepsStackProperty = (suffix) => `__reduxStepsStack${suffix}`;

export const getReduxStepsStackKeyFromSteps = (STEPS) =>
    getReduxStepsStackProperty(reduxStepsGetKey(STEPS));

/**
 * Create the initial state needed to manage the component's steps.
 */
const reduxStepsGetInitialState = (STEPS, initialStep = STEPS[0]) => ({
    [getReduxStepsStackKeyFromSteps(STEPS)]: getInitialStack(
        STEPS,
        initialStep,
    ),
});

/**
 * Mutates the step state in response to a SET_STEP action
 */
const reduxStepsSetStepReducer = (state, action) => {
    const { key, step, isInitialStep } = action.payload;

    const stack = state[getReduxStepsStackProperty(key)];
    if (!stack) {
        return {};
    }

    const newStack = isInitialStep ? [step] : [step, ...stack];
    return {
        [getReduxStepsStackProperty(key)]: newStack,
    };
};

/**
 * Mutate the step state in response to a GO_BACK action
 */
const reduxStepsGoBackReducer = (state, action) => {
    const { key } = action.payload;

    const stack = state[getReduxStepsStackProperty(key)];
    if (!stack) {
        return {};
    }

    if (stack.length === 1) {
        return {};
    }

    const newStack = stack.slice(1);
    return {
        [getReduxStepsStackProperty(key)]: newStack,
    };
};

const REDUX_STEPS_SET_STEP = 'REDUX_STEPS::SET_STEP';
const REDUX_STEPS_GO_BACK = 'REDUX_STEPS::GO_BACK';

const noopReducer = createReducer({}, noop);

export const withReduxStepsReducer = (STEPS, outerReducer = noopReducer) => {
    const initialState = {
        ...getInitialState(outerReducer),
        ...reduxStepsGetInitialState(STEPS),
    };

    return createReducer(initialState, (builder) => {
        builder.addCase(REDUX_STEPS_SET_STEP, (state, action) => ({
            ...state,
            ...reduxStepsSetStepReducer(state, action),
        }));
        builder.addCase(REDUX_STEPS_GO_BACK, (state, action) => ({
            ...state,
            ...reduxStepsGoBackReducer(state, action),
        }));
        builder.addDefaultCase((state, action) => ({
            ...state,
            ...outerReducer(state, action),
        }));
    });
};

export const reduxStepsSetStep = (STEPS, step) => ({
    type: REDUX_STEPS_SET_STEP,
    payload: {
        key: reduxStepsGetKey(STEPS),
        step,
    },
});

export const reduxStepsSetInitialStep = (STEPS, step) => ({
    type: REDUX_STEPS_SET_STEP,
    payload: {
        key: reduxStepsGetKey(STEPS),
        step,
        isInitialStep: true,
    },
});

export const reduxStepsGoBack = (STEPS) => ({
    type: REDUX_STEPS_GO_BACK,
    payload: {
        key: reduxStepsGetKey(STEPS),
    },
});

const reduxStepsStackSelector = (state, key) => {
    const stackProperty = getReduxStepsStackProperty(key);
    return state[stackProperty];
};

export const reduxStepsStepSelector = (state, key) =>
    reduxStepsStackSelector(state, key)[0];

export const useReduxSteps = (steps, initialStep = steps[0], options = {}) => {
    const dispatch = useDispatch();

    const key = options.key || reduxStepsGetKey(steps);
    const setStep = useCallback(
        (step, setStepOptions = {}) =>
            dispatch({
                type: REDUX_STEPS_SET_STEP,
                payload: {
                    key,
                    step,
                    isInitialStep: setStepOptions.isInitialStep || false,
                },
            }),
        [dispatch, key],
    );

    const goBack = useCallback(
        () =>
            dispatch({
                type: REDUX_STEPS_GO_BACK,
                payload: { key },
            }),
        [dispatch, key],
    );

    const subState = useSelector(options.rootSelector || noop);
    const step = reduxStepsStepSelector(subState, key);
    const stepStack = reduxStepsStackSelector(subState, key);

    useMountAndUnmount(() => {
        if (!step) {
            setStep(initialStep, { isInitialStep: true });
        }
        return () => setStep(initialStep);
    });

    return {
        goBack,
        setStep,
        step,
        stepStack,
    };
};
