export const CREDIT_KARMA_LIGHTBOX = 'CK_LB';
export const CREDIT_KARMA_INVITE_TO_APPLY = 'CK_ITA';
export const CREDIT_LAND = 'CL';
export const BEST_CREDIT_OFFERS = 'BCO';
export const RED_VENTURES_BANKRATE = 'RV_BR';
export const RED_VENTURES_BANKRATE_ITA = 'RV_BR_ITA';
export const RED_VENTURES_CREDIT_CARDS_DOT_COM = 'RV_CCDC';
export const RED_VENTURES_CREDIT_CARDS_DOT_COM_ITA = 'RV_CCDC_ITA';
export const BULLDOG_CREDIT_SOUP = 'BMG_CS';
export const WALLET_HUB = 'WH';
