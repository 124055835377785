import { useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { authentication } from '@perpay-web/authentication/singleton';
import { SIGNUP_PATH } from '@perpay-web/authentication/constants/paths';
import WarningLoaderCircle from '@perpay-web/components/base/WarningLoaderCircle/WarningLoaderCircle';
import { CARD_PARTNER_ONBOARDING_APPLY } from '@perpay-web/constants/paths';
import { useSelector } from 'react-redux';
import { REFRESHING } from '@perpay-web/authentication/constants/states';

const getIsRefreshing = (state) =>
    state.authentication.tokenState === REFRESHING;

const useRedirectIfIncomplete = () => {
    const navigate = useNavigate();
    const [isLoadingRedirect, setIsLoadingRedirect] = useState(true);
    const isAuthenticationRefreshing = useSelector(getIsRefreshing);

    useLayoutEffect(() => {
        if (isAuthenticationRefreshing) {
            return;
        }

        const params = new URLSearchParams(window.location.search);
        const postLoginPath = params.get('next') || window.location.pathname;
        params.set('next', postLoginPath);

        const missingRequirements = authentication.getSignupRequirements();
        const phoComplete = params.get('PHO_complete') || false;
        if (
            missingRequirements.includes('pho_submit_application') &&
            !phoComplete
        ) {
            navigate(`${CARD_PARTNER_ONBOARDING_APPLY}?${params}`, {
                replace: true,
            });
            return;
        }
        if (missingRequirements.length > 0) {
            if (!authentication.getIsPartnerOnboarded()) {
                navigate(`${SIGNUP_PATH}?${params}`, { replace: true });
                return;
            }
        }

        setIsLoadingRedirect(false);
    }, [isAuthenticationRefreshing, navigate]);

    return {
        isLoadingRedirect,
    };
};

export function withRedirectIfIncomplete(Component) {
    return (props) => {
        const { isLoadingRedirect } = useRedirectIfIncomplete();

        if (isLoadingRedirect) {
            return <WarningLoaderCircle />;
        }

        return <Component {...props} />;
    };
}
