import {
    STORE_REPLACE_ACCOUNT_DEACTIVATION_REASONS,
    STORE_RESET_ACCOUNT_DEACTIVATION_REASONS,
} from '@perpay-web/fintech/constants/actionTypes';

const initialState = {};

const accountDeactivationReasonReducer = (
    state = initialState,
    action = undefined,
) => {
    const { type, payload } = action;

    switch (type) {
        case STORE_REPLACE_ACCOUNT_DEACTIVATION_REASONS:
            return { ...payload };
        case STORE_RESET_ACCOUNT_DEACTIVATION_REASONS:
            return {};
        default:
            return state;
    }
};

export default accountDeactivationReasonReducer;
