import {
    STORE_REPLACE_BANKS,
    STORE_RESET_BANKS,
} from '@perpay-web/fintech/constants/actionTypes';

const initialState = {};

const bankReducer = (state = initialState, action = undefined) => {
    const { type, payload } = action;

    switch (type) {
        case STORE_REPLACE_BANKS:
            return { ...payload };
        case STORE_RESET_BANKS:
            return {};
        default:
            return state;
    }
};

export default bankReducer;
