import {
    STORE_REPLACE_JOBS,
    STORE_REPLACE_JOBS_ERROR,
} from '@perpay-web/fintech/constants/actionTypes';

const initialState = {
    errors: {},
};

const jobReducer = (state = initialState, action = undefined) => {
    const { type, payload } = action;
    switch (type) {
        case STORE_REPLACE_JOBS:
            return { ...state, ...payload, errors: {} };
        case STORE_REPLACE_JOBS_ERROR:
            return { ...state, errors: payload };
        default:
            return state;
    }
};

export default jobReducer;
