import {
    decodeTokens,
    expireRefreshCookie,
    removeToken,
    storeToken,
} from '@perpay-web/utils/tokenUtils';
import { expireCookie } from '@perpay-web/utils/cookieUtils';
import { SHOP_LAST_PAGE_COOKIE } from '@perpay-web/constants/cookies';
import { jsonFetch } from '@perpay-web/utils/requestUtils';
import {
    AUTH_ENDPOINT,
    AUTH_MFA_ENDPOINT,
    AUTH_MFA_RESEND_ENDPOINT,
    REFRESH_ENDPOINT,
    LOGOUT_ENDPOINT,
    STOREFRONT_LOGOUT_URL,
    USERS_ENDPOINT,
    PARTNER_ONBOARDING_ENDPOINT,
} from '@perpay-web/authentication/constants/urls';
import { HOST, PROD_HOST } from '@perpay-web/constants/urls';
import { allSettled } from '@perpay-web/utils/promiseUtils';
import { HTTPMethod } from '@perpay-web/constants/httpMethods';

const timeout = (delayMs) =>
    new Promise((resolve) => {
        setTimeout(resolve, delayMs);
    });

/**
 * `requestAuthenticate`, `requestRefresh`, `requestSignup` and `requestLogout` are responsible
 * for fetching and decoding the tokens returned by the authentication requests.
 * These methods do not manage internal state for the service.
 */

export const requestAuthenticate = (credentials) =>
    jsonFetch(AUTH_ENDPOINT, credentials, HTTPMethod.post).then((tokens) => {
        const decodedTokens = decodeTokens(tokens);
        // don't store mfa token in local storage.
        if (decodedTokens.mfa) {
            return decodedTokens.mfa;
        }
        storeToken(decodedTokens);
        return decodedTokens;
    });

export const requestAuthenticateMFA = (payload) =>
    jsonFetch(AUTH_MFA_ENDPOINT, payload, HTTPMethod.post).then((tokens) => {
        const decodedTokens = decodeTokens(tokens);
        storeToken(decodedTokens);
        return decodedTokens;
    });

export const requestResendMFA = (payload) =>
    jsonFetch(AUTH_MFA_RESEND_ENDPOINT, payload, HTTPMethod.post).then(
        (tokens) => {
            const decodedTokens = decodeTokens(tokens);
            return decodedTokens.mfa;
        },
    );

export const requestRefresh = (refreshToken) => {
    const body = { refresh: refreshToken };

    return jsonFetch(
        REFRESH_ENDPOINT,
        body,
        HTTPMethod.post,
        {},
        'include',
    ).then((tokens) => {
        const decodedTokens = decodeTokens({
            access: tokens.access,
            refresh: refreshToken,
        });
        storeToken(decodedTokens);
        return decodedTokens;
    });
};

export const requestSignup = (accountData) =>
    jsonFetch(USERS_ENDPOINT, accountData, HTTPMethod.post).then((results) => {
        const { tokens } = results;
        storeToken(decodeTokens(tokens));
        return results;
    });

export const requestPartnerHostedSignup = (userInfo) =>
    jsonFetch(PARTNER_ONBOARDING_ENDPOINT, userInfo, HTTPMethod.post).then(
        (results) => {
            const { tokens } = results;
            storeToken(decodeTokens(tokens));
            return results;
        },
    );

const STOREFRONT_LOGOUT_TIMEOUT_MS = 3000;

const requestStorefrontLogout = () =>
    Promise.race([
        fetch(STOREFRONT_LOGOUT_URL, {
            mode: 'no-cors', // do not require the server to send CORS headers
            credentials: 'include', // allow the server to set cookies
        }),
        timeout(STOREFRONT_LOGOUT_TIMEOUT_MS),
    ]);

// We pass the prod host since the cookie is stored on the root domain .perpay.com
const expireShopLastPageCookie = () =>
    expireCookie(PROD_HOST, SHOP_LAST_PAGE_COOKIE);

export const requestLogout = (refreshToken) =>
    allSettled([
        jsonFetch(
            LOGOUT_ENDPOINT,
            { refresh: refreshToken },
            HTTPMethod.post,
            {},
            'include',
        ),
        requestStorefrontLogout(),
    ]).then(() => {
        expireRefreshCookie(HOST);
        expireShopLastPageCookie();
        removeToken();
    });
