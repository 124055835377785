import { authentication } from '@perpay-web/authentication/singleton';
import { STORE_REPLACE_AUTH } from '@perpay-web/fintech/constants/actionTypes';
import {
    launchSupportTool as launchSupportToolAction,
    loadedSupportTool,
} from '@perpay-web/services/supportTool';
import { push } from '@perpay-web/services/router';
import { ofType } from 'redux-observable';
import { EMPTY, concat } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { dispatchObservable } from '@perpay-web/observable/dispatchObservable';

const SUPPORT_TOOL_AUTH_TIMEOUT = 1000 * 60 * 2; // 2 minutes

export const launchSupportTool = (action$, state$) =>
    action$.pipe(
        ofType(launchSupportToolAction().type),
        mergeMap((action) => {
            if (authentication.getIsAuthenticated()) {
                return EMPTY;
            }

            const params = new URLSearchParams();
            params.append('next', window.location.pathname);

            return concat(
                dispatchObservable({
                    action$,
                    state$,
                    initialDispatch: () => {
                        push(`/login?${params}`);
                        return EMPTY;
                    },
                    waitFor: [STORE_REPLACE_AUTH],
                    waitForDispatch: () =>
                        dispatchObservable({
                            action$,
                            state$,
                            waitFor: [loadedSupportTool().type],
                            waitForDispatch: () => [
                                launchSupportToolAction(
                                    false,
                                    action.payload.intercomBucket,
                                ),
                            ],
                        }),
                    timeout: SUPPORT_TOOL_AUTH_TIMEOUT,
                }),
            );
        }),
    );
