import {
    SIGNUP,
    sortSignupSteps,
} from '@perpay-web/fintech/constants/steps/signupSteps';
import {
    SIGNUP_FLOW_CARD_FIRST,
    SIGNUP_FLOW_MARKETPLACE,
    SIGNUP_FLOW_PARTNER_ONBOARDING,
} from '@perpay-web/fintech/constants/signupFlowTypes';
import { isInPartnerOnboardingFlow } from '@perpay-web/fintech/selectors/ui/partnerOnboarding';

export const getSignupRequirements = (state) =>
    sortSignupSteps(state.ui.signup.signupRequirements);

export const getIsSignupComplete = (state) =>
    getSignupRequirements(state).length === 0;

export const getInitialRequirements = (state) =>
    sortSignupSteps(state.ui.signup.initialRequirements);

export const getIsSignupInitiallyComplete = (state) =>
    getInitialRequirements(state).length === 0;

export const getSignupStep = (state) =>
    getSignupRequirements(state)[0] || SIGNUP;

export const getSignupLoading = (state) => state.ui.signup.signupLoading;

export const getSignupErrors = (state) => state.ui.signup.errors;

export const getJobUUID = (state) => state.ui.signup.jobUUID;

export const getPhoneStep = (state) => state.ui.signup.phoneStep;

export const getIsCardFirstSignupFlow = (state) =>
    state.ui.signup.isCardFirstUser;

export const getIsCardInviteLoading = (state) =>
    state.ui.signup.cardInviteLoading;

export const getSignupFlowType = (state) => {
    if (getIsCardFirstSignupFlow(state)) {
        return SIGNUP_FLOW_CARD_FIRST;
    }
    if (isInPartnerOnboardingFlow(state)) {
        return SIGNUP_FLOW_PARTNER_ONBOARDING;
    }
    return SIGNUP_FLOW_MARKETPLACE;
};

export const getCardInviteCode = (state) => state.ui.signup.inviteCode;

export const getIsInviteCodeVerifyStepComplete = (state) =>
    state.ui.signup.inviteCodeVerificationCompleted;
