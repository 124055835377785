import { createAction } from '@reduxjs/toolkit';

export const createWithdrawalRequest = createAction(
    'backend/WITHDRAWAL::CREATE',
);

export const createWithdrawalRequestUI = createAction(
    'backend/WITHDRAWAL::CREATE_UI',
);

export const createWithdrawalRequestSuccess = createAction(
    'backend/WITHDRAWAL::CREATE::SUCCESS',
);

export const createWithdrawalRequestErrors = createAction(
    'backend/WITHDRAWAL::CREATE::ERROR',
);

export const fetchWithdrawalBalance = createAction('backend/WITHDRAWAL::FETCH');

export const replaceWithdrawalBalance = createAction(
    'store/WITHDRAWAL::REPLACE',
);

export const withdrawalBalanceError = createAction('store/WITHDRAWAL::ERROR');
