import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    fetchPartnerHostedCardApplication,
    fetchPartnerHostedCardApplicationSuccess,
    fetchPartnerHostedCardApplicationError,
    fetchPartnerHostedCardApplicationReset,
} from '@perpay-web/fintech/actions/ui/partnerHostedCardApplication';
import { PLASTIC_MATERIAL } from '@perpay-web/fintech/utils/cardAccountApplicationMetalUtils';

const PartnerHostedCardApplicationDataModule = createDataModule({
    dataRequest: fetchPartnerHostedCardApplication,
    dataSuccess: fetchPartnerHostedCardApplicationSuccess,
    dataError: fetchPartnerHostedCardApplicationError,
    dataReset: fetchPartnerHostedCardApplicationReset,
});

const getRoot = (state) => state.dataModules.partnerHostedCardApplication;
const initialValue = {};

export const partnerHostedCardApplicationDataModule =
    PartnerHostedCardApplicationDataModule({
        getRoot,
        initialValue,
    });

export const getPartnerHostedCreditLimit = (state) =>
    partnerHostedCardApplicationDataModule.getData(state).creditLimit || 0;

export const getPartnerHostedAPR = (state) =>
    partnerHostedCardApplicationDataModule.getData(state).apr || 0;

export const getPartnerHostedMinimumPayment = (state) =>
    partnerHostedCardApplicationDataModule.getData(state)
        .estimatedCardMinimumPayment || 0;

export const getPartnerHostedApplicationUUID = (state) =>
    partnerHostedCardApplicationDataModule.getData(state).uuid || '';

export const getPartnerHostedMaterial = (state) =>
    partnerHostedCardApplicationDataModule.getData(state).material ||
    PLASTIC_MATERIAL;
