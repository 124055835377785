import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { SHOP_LAST_PAGE_COOKIE } from '@perpay-web/constants/cookies';
import { getCookie, expireCookie } from '@perpay-web/utils/cookieUtils';
import { pathIsInternalToApp } from '@perpay-web/utils/routeUtils';
import CustomSharedLink from '@perpay-web/components/base/CustomSharedLink/CustomSharedLink';
import { useEndToEndTestId } from '@perpay-web/hooks/useEndToEndTestId';
import { routeToLocation } from '@perpay-web/fintech/actions/router';
import { PROD_HOST, MAGENTO_URL } from '@perpay-web/constants/urls';
import { authentication } from '@perpay-web/fintech/settings/singletons';

const ANALYTICS_REQUEST_DELAY_MS = 300;

const CustomLink = forwardRef(
    ({ onClick, children, href, state: unusedState, ...rest }, ref) => {
        let linkHref = href;
        if (linkHref === MAGENTO_URL) {
            const shopLastPageCookie = getCookie(SHOP_LAST_PAGE_COOKIE);

            if (shopLastPageCookie) {
                linkHref = shopLastPageCookie;
            }
        }
        const endToEndTestIDParams = useEndToEndTestId();

        const handleClick = (event) => {
            const shopLastPageCookie = getCookie(SHOP_LAST_PAGE_COOKIE);
            if (linkHref.startsWith(MAGENTO_URL) && shopLastPageCookie) {
                expireCookie(PROD_HOST, SHOP_LAST_PAGE_COOKIE);
            }
            if (
                (window.PRODUCTION || window.STAGING) &&
                linkHref.startsWith(MAGENTO_URL) &&
                !authentication.getIsAccessTokenExpired()
            ) {
                const accessToken = authentication.getAccessToken();
                // Synchronously navigate if we do not need to wait for an analytics onClick
                if (!onClick) {
                    const { target: linkElement } = event;
                    // TODO: Remove once Magento does not need jwt in url
                    linkElement.href = `${MAGENTO_URL}jwt?access=${accessToken}&next=${linkHref}`;
                    return;
                }

                // If an onClick is present, then we delay the redirect to give any analytics in
                // the onClick a little extra time to successfully complete.
                // Based on the Segment analytics.js approach to link tracking
                // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#track-link
                event.preventDefault();
                onClick(linkHref);
                setTimeout(() => {
                    // TODO: Remove jwt once Magento does not need jwt in url
                    window.location.href = `${MAGENTO_URL}jwt?access=${accessToken}&next=${linkHref}`;
                }, ANALYTICS_REQUEST_DELAY_MS);
                return;
            }

            if (event.metaKey || event.ctrlKey) {
                // Allow normal link behavior to continue
                onClick(linkHref);
                return;
            }

            // Let external links be handled by the browser
            // Internal links will be handled in the onClick handler
            if (!pathIsInternalToApp(linkHref)) {
                onClick(linkHref);
                return;
            }

            event.preventDefault();
            onClick(linkHref);
        };

        return (
            <CustomSharedLink
                ref={ref}
                href={linkHref}
                onClick={handleClick}
                {...endToEndTestIDParams}
                {...rest}
            >
                {children}
            </CustomSharedLink>
        );
    },
);

CustomLink.propTypes = {
    onClick: PropTypes.func.isRequired,
    children: PropTypes.node,
    href: PropTypes.string.isRequired,
    target: PropTypes.string,
    className: PropTypes.string,
    action: PropTypes.oneOf([
        'attention',
        'inline-link',
        'link',
        'primary',
        'secondary',
        'tertiary',
    ]),
    state: PropTypes.objectOf(PropTypes.string),
    size: PropTypes.oneOf(['small', 'medium', 'large', '']),
};

CustomLink.defaultProps = {
    target: null,
    className: '',
    action: 'inline-link',
    size: '',
    children: null,
    state: null,
};

CustomLink.displayName = 'CustomLink';

const mapDispatchToProps = (dispatch, ownProps) => ({
    onClick: (href) => {
        if (ownProps.onClick) {
            ownProps.onClick();
        }
        if (ownProps.target !== '_blank') {
            dispatch(
                routeToLocation({
                    path: href,
                    props: ownProps.state,
                }),
            );
        }
    },
});

// Legacy usage of Redux connect API. New code should not use this pattern
// eslint-disable-next-line @perpay-web/no-redux-connect
export default connect(null, mapDispatchToProps, null, {
    forwardRef: true,
})(CustomLink);
