import { ofType } from 'redux-observable';
import { mergeMap, exhaustMap, withLatestFrom } from 'rxjs/operators';
import { reduxStepsSetStep } from '@perpay-web/hooks/useReduxSteps';
import { handleErrorMessageWithFallback } from '@perpay-web/observable/operators/handleErrorMessageWithFallback';
import {
    CARD_ONBOARDING_STEPS,
    IN_REVIEW_STEP,
} from '@perpay-web/fintech/constants/steps/cardOnboardingSteps';

import { CARD_DOCUMENTS_ENDPOINT } from '@perpay-web/fintech/constants/urls';
import {
    uploadCardDocuments as uploadCardDocumentsAction,
    uploadCardDocumentsSuccess,
    uploadCardDocumentsError,
} from '@perpay-web/fintech/actions/entities/cardDocuments';
import { getCardAccountApplicationUuid } from '@perpay-web/fintech/dataModules/crud/cardAccountApplication';
import * as partnerOnboardingSteps from '@perpay-web/fintech/constants/steps/cardPartnerOnboardingSteps';

export function uploadCardDocuments(action$, state$, { post }) {
    return action$.pipe(
        ofType(uploadCardDocumentsAction().type),
        withLatestFrom(state$),
        exhaustMap(([action, state]) => {
            const cardAccountApplicationUuid =
                getCardAccountApplicationUuid(state);
            const { proofOfIdFile, proofOfAddressFile } = action.payload;
            const payload = new window.FormData();
            payload.append(
                'cardAccountApplicationUuid',
                cardAccountApplicationUuid,
            );
            if (proofOfIdFile) payload.append('proofOfIdFile', proofOfIdFile);
            if (proofOfAddressFile)
                payload.append('proofOfAddressFile', proofOfAddressFile);
            return post(CARD_DOCUMENTS_ENDPOINT, payload);
        }),
        mergeMap(() => [
            uploadCardDocumentsSuccess({}),
            reduxStepsSetStep(CARD_ONBOARDING_STEPS, IN_REVIEW_STEP),
            reduxStepsSetStep(
                Object.values(partnerOnboardingSteps),
                partnerOnboardingSteps.IN_REVIEW_STEP,
            ),
        ]),
        handleErrorMessageWithFallback((error) => [
            uploadCardDocumentsError(error),
        ]),
    );
}
