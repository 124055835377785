import {
    componentStackProviderFactory,
    useComponentStackRender,
} from '@perpay-web/hooks/useComponentStack';
import { createContextProvider } from '@perpay-web/utils/componentUtils';

const [CenterHeaderComponentStackProvider, useCenterHeaderContext] =
    createContextProvider(componentStackProviderFactory);

export { CenterHeaderComponentStackProvider };

export const useCenterHeaderRender = () =>
    useComponentStackRender(useCenterHeaderContext());
