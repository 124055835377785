import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * This hook is used to trigger timed events
 *
 * triggeredCallback is called `timerDurationMs` after the
 * returned `startTimer` function is called.
 *
 *
 * Example Usage:
 * ```
 * const MyComponent() => {
 *   const timedEventCallback = useCallback(() => { console.log('timed out') }, []);
 *   const { startTimer, clearTimer } = useTimedEvent(timedEventCallback, 5000);
 *   return (
 *      <div>
 *          <button onClick={startTimer}>Start</p>
 *          <button onClick={clearTimer}>Clear</p>
 *      </div>
 *   );
 * };
 * ```
 */
export function useTimedEvent(triggeredCallback, timerDurationMs) {
    const timerIdRef = useRef();
    const [isTimerActive, setIsTimerActive] = useState(false);

    const clearTimer = useCallback(() => {
        if (timerIdRef.current) {
            window.clearTimeout(timerIdRef.current);
            setIsTimerActive(false);
        }
    }, []);

    const startTimer = useCallback(() => {
        timerIdRef.current = window.setTimeout(() => {
            triggeredCallback();
            setIsTimerActive(false);
        }, timerDurationMs);
        setIsTimerActive(true);
    }, [triggeredCallback, timerDurationMs]);

    useEffect(() => () => clearTimer(), [clearTimer]);

    return { startTimer, clearTimer, isTimerActive };
}
