import { composeDataModules } from '@perpay-web/data-module/composeDataModules';
import {
    generalAccountSummaryDataModule,
    getMostRecentDirectDeposit as getGeneralAccountSummaryRecentDirectDeposit,
} from '@perpay-web/fintech/dataModules/generalAccountSummary';
import { fetchCardAccountsDataModule } from '@perpay-web/fintech/dataModules/fetchCardAccounts';
import { gateCardAccountDataModule } from '@perpay-web/fintech/dataModules/gateCardAccount';
import { fetchBankAccountsForCardAutoPayDataModule } from '@perpay-web/fintech/dataModules/fetchBankAccountsForCardAutoPay';
import { fetchAutoPaySchedulesDataModule } from '@perpay-web/fintech/dataModules/fetchAutoPaySchedules';
import { fetchCardStatementsDataModule } from '@perpay-web/fintech/dataModules/fetchCardStatements';

const CardCreateAutoPay = composeDataModules([
    generalAccountSummaryDataModule,
    fetchCardAccountsDataModule,
    gateCardAccountDataModule,
    fetchBankAccountsForCardAutoPayDataModule,
    fetchAutoPaySchedulesDataModule,
    fetchCardStatementsDataModule,
]);

const getRoot = (state) => state.dataModules.cardCreateAutoPay;

export const cardCreateAutoPayDataModule = CardCreateAutoPay({
    getRoot,
    key: 'CARD_CREATE_AUTO_PAY',
});

export const getMostRecentDirectDeposit = (state) =>
    getGeneralAccountSummaryRecentDirectDeposit(state) || {
        amount: 0,
        date: '',
    };

export {
    getRemainingMinimumPaymentDue,
    getRemainingStatementBalance,
    getCurrentBalance,
} from '@perpay-web/fintech/dataModules/fetchCardAccounts';

export { getNextDirectDepositDate } from '@perpay-web/fintech/dataModules/generalAccountSummary';

export {
    getRecurringAutoPayScheduleExists,
    getOneTimeAutoPayExists,
} from '@perpay-web/fintech/dataModules/fetchAutoPaySchedules';
