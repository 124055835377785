import { withReduxStepsReducer } from '@perpay-web/hooks/useReduxSteps';
import {
    BACKEND_CARD_ACTIVATE,
    STORE_CARD_ACTIVATE_SUCCESS,
    STORE_CARD_ACTIVATE_ERROR,
    STORE_CARD_ACTIVATE_REPLACE_LAST_FOUR,
    STORE_REPLACE_USER_STATUS,
    STORE_REPLACE_USER_STATUS_ERROR,
} from '@perpay-web/fintech/constants/actionTypes';
import { CARD_ACTIVATION_STEPS } from '@perpay-web/fintech/constants/steps/cardActivationSteps';

const initialState = {
    errors: {},
    submitting: false,
    cardUuid: '',
};

const cardActivationReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;

    switch (type) {
        case STORE_REPLACE_USER_STATUS:
            return {
                ...state,
                errors: {},
                cardUuid: payload.metadata.cardIdentifier,
            };
        case STORE_REPLACE_USER_STATUS_ERROR:
            return { ...state, errors: payload };
        case BACKEND_CARD_ACTIVATE:
            return {
                ...state,
                errors: {},
                submitting: true,
            };
        case STORE_CARD_ACTIVATE_REPLACE_LAST_FOUR:
            return {
                ...state,
                errors: {},
                submitting: false,
            };
        case STORE_CARD_ACTIVATE_SUCCESS:
            return { ...state, submitting: false };
        case STORE_CARD_ACTIVATE_ERROR:
            return { ...state, errors: payload, submitting: false };
        default:
            return state;
    }
};

export default withReduxStepsReducer(
    CARD_ACTIVATION_STEPS,
    cardActivationReducer,
);
