import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { handleError } from '@perpay-web/observable/operators/handleError';

import { FEATURE_SWITCHES_ENDPOINT } from '@perpay-web/fintech/constants/urls';
import {
    fetchFeatureSwitchRequest,
    fetchFeatureSwitchSuccess,
    fetchFeatureSwitchError,
} from '@perpay-web/fintech/actions/shared/featureSwitches';

export function fetchFeatureSwitch(action$, state$, { get }) {
    return action$.pipe(
        ofType(fetchFeatureSwitchRequest().type),
        mergeMap(({ payload }) =>
            get(
                `${FEATURE_SWITCHES_ENDPOINT}?feature_switch_name=${payload.featureName}`,
            ).pipe(
                mergeMap((results) => [
                    fetchFeatureSwitchSuccess({
                        [payload.storeName]: results.response,
                    }),
                ]),
                handleError((error) => [
                    // Pass the payload (flagName) in case of an error as well
                    fetchFeatureSwitchError({ [payload.storeName]: error }),
                ]),
            ),
        ),
    );
}
