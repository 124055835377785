/**
 * Handles marketing related tasks, like the
 * storage and retrieval of UTM params.
 */

import { PERPAY_UTM_COOKIE } from '@perpay-web/constants/cookies';
import { getURLSearchParam } from '@perpay-web/utils/urlUtils';
import { setCookie, getCookie } from '@perpay-web/utils/cookieUtils';
import { normalizePhoneNumber } from '@perpay-web/utils/stringUtils';

export function storeUtmCookie(host) {
    const utms = {
        utm_campaign: getURLSearchParam('utm_campaign'),
        utm_source: getURLSearchParam('utm_source'),
        utm_medium: getURLSearchParam('utm_medium'),
        utm_term: getURLSearchParam('utm_term'),
        utm_content: getURLSearchParam('utm_content'),
        iterableTemplateId: getURLSearchParam('templateId'),
        iterableCampaignId: getURLSearchParam('campaignId'),
    };

    const definedUtms = Object.values(utms).filter(Boolean);
    const hasNewUtm = definedUtms.length > 0;
    if (!hasNewUtm) {
        return;
    }

    setCookie(PERPAY_UTM_COOKIE, JSON.stringify(utms), host);
}

export function retrieveUtmCookie() {
    const value = getCookie(PERPAY_UTM_COOKIE);

    if (!value) {
        return {};
    }

    try {
        return JSON.parse(value);
    } catch (unusedError) {
        // swallow the error
    }

    return {};
}

const getContextCampaign = () => {
    const utms = retrieveUtmCookie();

    return {
        name: utms.utm_campaign,
        source: utms.utm_source,
        medium: utms.utm_medium,
        term: utms.utm_term,
        content: utms.utm_content,
    };
};

export const getSegmentContext = () => ({
    campaign: getContextCampaign(),
});

export const getSegmentTraitsFromAnalyticsIdentity = (
    analyticsIdentity = {},
) => ({
    uuid: analyticsIdentity.user_uuid,
    email: analyticsIdentity.email,
    company: analyticsIdentity.company,
    firstName: analyticsIdentity.first_name,
    lastName: analyticsIdentity.last_name,
    phone: analyticsIdentity.phone_number
        ? normalizePhoneNumber(analyticsIdentity.phone_number)
        : undefined,
    address: {
        city: analyticsIdentity.city,
        country: analyticsIdentity.country,
        postalCode: analyticsIdentity.postal_code,
        state: analyticsIdentity.state,
    },
});
