import { useLayoutEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const MagentoRedirect = ({ storefrontPath, magentoRewrite }) => {
    const params = useParams();
    const navigate = useNavigate();

    useLayoutEffect(() => {
        const targetRoute = magentoRewrite
            ? magentoRewrite(params)
            : Object.entries(params).reduce(
                  (route, [key, value]) => route.replace(`:${key}`, value),
                  storefrontPath,
              );
        navigate(
            { pathname: targetRoute, search: window.location.search },
            { replace: true },
        );
    }, [storefrontPath, params, magentoRewrite, navigate]);
};

export default MagentoRedirect;
