import {
    ACKNOWLEDGE_NOTIFICATION_ERROR,
    ACKNOWLEDGE_NOTIFICATION_SUCCESS,
    FETCH_NOTIFICATIONS_SUCCESS,
} from '@perpay-web/fintech/constants/actionTypes';

const initialState = {};

const notificationsReducer = (state = initialState, action = undefined) => {
    const { type, payload } = action;
    switch (type) {
        case FETCH_NOTIFICATIONS_SUCCESS:
            return { ...state, ...payload };
        case ACKNOWLEDGE_NOTIFICATION_SUCCESS:
        case ACKNOWLEDGE_NOTIFICATION_ERROR:
            return state;
        default:
            return state;
    }
};

export default notificationsReducer;
