import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    fetchFeatureSwitchRequest,
    fetchFeatureSwitchSuccess,
    fetchFeatureSwitchReset,
    fetchFeatureSwitchError,
} from '@perpay-web/fintech/actions/shared/featureSwitches';

const FetchFeatureSwitchesDataModule = createDataModule({
    dataRequest: fetchFeatureSwitchRequest,
    dataSuccess: fetchFeatureSwitchSuccess,
    dataError: fetchFeatureSwitchError,
    dataReset: fetchFeatureSwitchReset,
});

const getRoot = (state) => state.dataModules.fetchFeatureSwitches;
const initialValue = {};

export const fetchFeatureSwitchesDataModule = FetchFeatureSwitchesDataModule({
    getRoot,
    initialValue,
});

const getFeatureSwitches = (state) =>
    fetchFeatureSwitchesDataModule.getData(state);

const getFeatureSwitchByName = (state, featureSwitchName) =>
    getFeatureSwitches(state)[featureSwitchName] || {};

export const getFeatureSwitchEnabled = (state, featureSwitchName) => {
    const feaureSwitch = getFeatureSwitchByName(state, featureSwitchName);
    return feaureSwitch.isEnabled || false;
};
