import { wrapRetry } from '@perpay-web/utils/importUtils';

export const importCardLearnContainer = () =>
    wrapRetry(
        () =>
            import(
                /* webpackChunkName: "importCardLearnContainer" */
                '@perpay-web/fintech/components/containers/CardLearnContainer'
            ),
    );
