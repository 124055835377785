import { emitter } from '@marvelapp/react-ab-test';
import { backendABTestMap } from '@perpay-web/constants/experiments';

const experimentVariantMap = {};

// Infrequently used, but used often enough where we want to keep in the repo
export function defineExperiment(name, variants, weights) {
    emitter.defineVariants(name, variants, weights);
    experimentVariantMap[name] = variants;
}

export function getExperimentSet() {
    const experimentSet = [];

    // Frontend AB Tests
    Object.keys(experimentVariantMap).forEach((experiment) => {
        const activeVariant = emitter.getActiveVariant(experiment);
        const experimentData = {
            experiment,
            active_variant: activeVariant,
            type: 'frontend',
        };
        experimentSet.push(experimentData);
    });

    // Backend AB Tests
    Object.keys(backendABTestMap).forEach((experiment) => {
        const experimentName = backendABTestMap[experiment];
        const experimentData = {
            experiment: experimentName,
            type: 'backend',
        };
        experimentSet.push(experimentData);
    });

    return experimentSet;
}
