import { lazy } from 'react';

import { compose } from '@perpay-web/utils/functionalUtils';

import { withLoader } from '@perpay-web/components/higher-order/WithLoader/WithLoader';
import { withPerpayTitle } from '@perpay-web/components/higher-order/WithPerpayTitle/WithPerpayTitle';
import { paths } from '@perpay-web/fintech/props/appPaths';
import { importBorrowerSignup } from '@perpay-web/fintech/utils/imports/importBorrowerSignup';
import { importBorrowerLoginContainer } from '@perpay-web/fintech/utils/imports/importBorrowerLoginContainer';
import { withSignupRedirectCheck } from '@perpay-web/fintech/components/higher-order/WithSignupRedirectCheck/WithSignupRedirectCheck';
import { withLoginRedirectCheck } from '@perpay-web/fintech/components/higher-order/WithLoginRedirectCheck/WithLoginRedirectCheck';
import { withCardFirstSignupVerifyInviteCode } from '@perpay-web/fintech/components/higher-order/WithCardFirstSignupVerifyInviteCode/WithCardFirstSignupVerifyInviteCode';
import BorrowerLogout from '@perpay-web/fintech/components/containers/BorrowerLogoutContainer';
import { CARD_SIGNUP_MVP_IS_LAUNCHED } from '@perpay-web/fintech/constants/flags';
import { withBottomNav } from '@perpay-web/components/higher-order/WithBottomNav/WithBottomNav';
import { importPartnerOnboardingSignupContainer } from '@perpay-web/fintech/utils/imports/importPartnerOnboardingSignupContainer';

const EmptyBottomNav = () => null;
const BorrowerLoginRoute = compose(
    withPerpayTitle('Login'),
    withLoginRedirectCheck,
    withBottomNav(EmptyBottomNav),
)(withLoader(lazy(importBorrowerLoginContainer)));
const PartnerSignupRoute = compose(
    withPerpayTitle('Sign up'),
    withLoginRedirectCheck,
    withBottomNav(EmptyBottomNav),
)(withLoader(lazy(importPartnerOnboardingSignupContainer)));

const identity = (x) => x;

const BorrowerSignupRoute = compose(
    withPerpayTitle('Sign up'),
    withSignupRedirectCheck,
    withBottomNav(EmptyBottomNav),
    CARD_SIGNUP_MVP_IS_LAUNCHED
        ? withCardFirstSignupVerifyInviteCode
        : identity,
)(withLoader(lazy(importBorrowerSignup)));

/**
 * @type {import('react-router-dom').DataRouteObject[]}
 */
export const authenticatingRoutes = [
    { path: paths.signUp.path, element: <BorrowerSignupRoute /> },
    { path: `${paths.login.path}/*`, element: <BorrowerLoginRoute /> },
    { path: paths.logout.path, element: <BorrowerLogout /> },
    {
        path: paths.partnerOnboardingInvitation.path,
        element: <PartnerSignupRoute />,
    },
];
