import { APP_UPDATE_QUEUE_REFRESH } from '@perpay-web/fintech/constants/actionTypes';

const initialState = {
    clientVersion: window.VERSION,
};

const appUpdateReducer = (state = initialState, action = undefined) => {
    const { type, payload } = action;
    switch (type) {
        case APP_UPDATE_QUEUE_REFRESH:
            return { ...state, clientVersion: payload.version };
        default:
            return state;
    }
};

export default appUpdateReducer;
