import StepsProviders from '@perpay-web/fintech/components/base/StepsProviders/StepsProviders';
import ComponentStackProviders from '@perpay-web/fintech/components/base/ComponentStackProviders/ComponentStackProviders';
import ModalProviders from '@perpay-web/fintech/components/base/ModalProviders/ModalProviders';
import { PartnerHostedProvider } from '@perpay-web/fintech/hooks/usePartnerHostedContext';
import { createCombinedProvider } from '@perpay-web/utils/componentUtils';

const FintechGlobalProviders = createCombinedProvider(
    ComponentStackProviders,
    StepsProviders,
    ModalProviders,
    PartnerHostedProvider,
);

export default FintechGlobalProviders;
