import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    fetchAutoPaySchedules,
    fetchAutoPaySchedulesSuccess,
    fetchAutoPaySchedulesError,
    fetchAutoPaySchedulesReset,
} from '@perpay-web/fintech/actions/entities/autoPaySchedules';
import { ONE_TIME } from '@perpay-web/fintech/constants/autoPayScheduleScheduleTypes';

const FetchAutoPaySchedulesDataModule = createDataModule({
    dataRequest: fetchAutoPaySchedules,
    dataSuccess: fetchAutoPaySchedulesSuccess,
    dataError: fetchAutoPaySchedulesError,
    dataReset: fetchAutoPaySchedulesReset,
});

const getRoot = (state) => state.dataModules.fetchAutoPaySchedules;
const initialValue = {
    all: [],
};

export const fetchAutoPaySchedulesDataModule = FetchAutoPaySchedulesDataModule({
    getRoot,
    initialValue,
});

export const getOneTimeAutoPaySchedules = (state) => {
    const { all } = fetchAutoPaySchedulesDataModule.getData(state);
    return all.filter(
        (autoPaySchedule) => autoPaySchedule.schedule === ONE_TIME,
    );
};

export const getRecurringAutoPaySchedule = (state) => {
    const { all } = fetchAutoPaySchedulesDataModule.getData(state);
    return all.find((autoPaySchedule) => autoPaySchedule.schedule !== ONE_TIME);
};

export const getRecurringAutoPayScheduleExists = (state) =>
    Boolean(getRecurringAutoPaySchedule(state));

export const getOneTimeAutoPayExists = (state) =>
    getOneTimeAutoPaySchedules(state).length > 0;
