/**
 * Selectors to access state values
 */

import {
    AUTHENTICATED,
    REFRESHING,
} from '@perpay-web/authentication/constants/states';

export const getRefreshToken = (state) => state.refresh.token;

export const getAccessToken = (state) => state.access.token;

export const getDecodedTokens = (state) => ({
    refresh: state.refresh,
    access: state.access,
});

export const getIsRefreshing = (state) => state.tokenState === REFRESHING;

export const getIsAuthenticated = (state) =>
    [REFRESHING, AUTHENTICATED].includes(state.tokenState);

export const getSignupRequirements = (state) => {
    if (!getIsAuthenticated(state)) {
        return [];
    }

    if (
        !state.access ||
        !state.access.payload ||
        !state.access.payload.signup_requirements
    ) {
        return [];
    }

    return state.access.payload.signup_requirements;
};

// TODO: fix for exact property that shows user is partner onboarded
export const getIsPartnerOnboarded = (state) => {
    const payload = (state.access || {}).payload || {};
    return payload.is_partner_onboarded || false;
};

export const getUserUuid = (state) => {
    const payload = (state.access || {}).payload || {};
    return payload.user_uuid;
};

export const getPartnerOnboardedCode = (state) => {
    const payload = (state.access || {}).payload || {};
    return payload.partner_onboarded_code || '';
};

export const getMaxPays = (state) => {
    const payload = (state.access || {}).payload || {};
    const repaymentOptions = payload.payment_options || [];
    const maxPayOption = repaymentOptions[repaymentOptions.length - 1] || {};
    return maxPayOption.number_of_payments || 8;
};

export const getAvailableSpendingLimit = (state) => {
    const payload = (state.access || {}).payload || {};
    const availableSpendingLimit = payload.available_spending_limit || 0;
    return availableSpendingLimit;
};

export const getPhoneNumber = (state) => {
    const payload = (state.access || {}).payload || {};
    const phoneNumber = payload.phone_number || '';
    return phoneNumber;
};

/**
 * Utility methods
 */
const EXPIRE_THRESHOLD_MS = 5000;

export const getIsTokenExpired = (decodedToken) => {
    if (!decodedToken || !decodedToken.payload) {
        return true;
    }
    const expiresMinusEager =
        1000 * decodedToken.payload.exp - EXPIRE_THRESHOLD_MS;
    return Date.now() > expiresMinusEager;
};
