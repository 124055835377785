import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import {
    exhaustMap,
    mergeMap,
    switchMap,
    withLatestFrom,
} from 'rxjs/operators';

import { getAnalyticsIdentity } from '@perpay-web/utils/tokenUtils';
import { normalize } from 'normalizr';
import { dispatchObservable } from '@perpay-web/observable/dispatchObservable';
import { reduxStepsSetStep } from '@perpay-web/hooks/useReduxSteps';
import { handleErrorMessageWithFallback } from '@perpay-web/observable/operators/handleErrorMessageWithFallback';
import {
    BACKEND_CREATE_PRIMARY_JOB,
    BACKEND_UPDATE_SALARY_INFO,
    BACKEND_CREATE_PHONE,
    BACKEND_SIGNUP,
    BACKEND_VERIFY_PHONE,
    STORE_REPLACE_AUTH,
    BACKEND_VERIFY_CARD_INVITE_CODE,
} from '@perpay-web/fintech/constants/actionTypes';
import {
    JOBS_ENDPOINT,
    SET_PHONE_ENDPOINT,
    VERIFY_CARD_INVITE_CODE_ENDPOINT,
} from '@perpay-web/fintech/constants/urls';
import {
    replaceAuthTokens,
    refreshAccessToken,
    resetRecaptcha,
} from '@perpay-web/fintech/actions/authentication';
import {
    primaryJobError,
    primaryJobSuccess,
    salaryInfoError,
    salaryInfoSuccess,
    setPhoneError,
    setPhoneSuccess,
    signupError,
    signupSuccess,
    verifyPhoneError,
    verifyPhoneSuccess,
    verifyCardInviteCodeError,
    verifyCardInviteCodeSuccess,
    analyticsOnboardingAddPhoneNumber,
    analyticsOnboardingVerifyPhoneNumber,
    analyticsOnboardingAddNetPay,
    analyticsOnboardingAddCompany,
    analyticsSignupSuccess,
} from '@perpay-web/fintech/actions/ui/signup';
import {
    getJobUUID,
    getIsSignupComplete,
    getIsCardFirstSignupFlow,
    getSignupFlowType,
} from '@perpay-web/fintech/selectors/ui/signup';
import { analyticsIdentifyUser } from '@perpay-web/fintech/actions/analytics/userInfo';
import { installSegmentMiddleware } from '@perpay-web/fintech/utils/analyticsUtils';
import { getUserInfo } from '@perpay-web/fintech/selectors/entities/userInfo';
import { job } from '@perpay-web/fintech/normalizers/schemas';
import { JOBS } from '@perpay-web/fintech/constants/tableNames';
import { replaceJobs } from '@perpay-web/fintech/actions/entities/jobs';
import { authentication } from '@perpay-web/fintech/settings/singletons';
import {
    postAuthRedirect,
    routeToLocation,
} from '@perpay-web/fintech/actions/router';
import { resetGateCardApply } from '@perpay-web/fintech/actions/entities/gateCardApply';
import { getIsGateCardApply } from '@perpay-web/fintech/dataModules/gateCardApply';
import {
    CARD_ONBOARDING_MVP_2_STEPS,
    ADD_ADDRESS_STEP,
} from '@perpay-web/fintech/constants/steps/cardOnboardingMVP2Steps';
import { paths } from '@perpay-web/fintech/props/appPaths';
import * as metalSteps from '@perpay-web/fintech/constants/steps/cardOnboardingMetalSteps';
import { completeSignupRequirements } from '@perpay-web/fintech/actions/entities/partnerOnboarding';
import { isInPartnerOnboardingFlow } from '@perpay-web/fintech/selectors/ui/partnerOnboarding';
import { getPartnerNameFromCode } from '@perpay-web/fintech/utils/partnerOnboardingUtils';

export function signup(action$, state$) {
    return action$.pipe(
        ofType(BACKEND_SIGNUP),
        exhaustMap((action) => authentication.signup(action.payload)),
        mergeMap((results) =>
            from(installSegmentMiddleware().then(() => results)),
        ),
        withLatestFrom(state$),
        mergeMap(([results, state]) => {
            const { tokens: unusedTokens, ...rest } = results;
            const decodedTokens = authentication.getDecodedTokens();
            const identityAction = getAnalyticsIdentity(
                decodedTokens,
                getUserInfo(state),
            );
            const signupFlow = getSignupFlowType(state);
            return [
                analyticsIdentifyUser(identityAction),
                // NOTE:
                // We store the WHOLE refresh and access tokens in the Redux store.
                // Pro:
                // This helps make the app reactive to changes in the token state
                // Con:
                // The source of truth for authentication state is divided between local storage,
                // the redux store, and the authentication service.
                //
                // Ultimately we should instead pull specific pieces we need for
                // UI Reactivity out of the token and store that in the redux store via dedicated
                // actions dispatched here by the authentication epics.
                // Will we ever do that? This is unclear. The Con above may be technical debt
                // we can live with.
                replaceAuthTokens(decodedTokens),
                signupSuccess(rest),
                analyticsSignupSuccess({ ...rest, signupFlow }),
            ];
        }),
        handleErrorMessageWithFallback((error) => [
            signupError(error),
            resetRecaptcha(),
        ]),
    );
}

const REPLACE_AUTH_TIMEOUT = 8000; // ms

export function salaryInfo(action$, state$, { patch }) {
    return action$.pipe(
        ofType(BACKEND_UPDATE_SALARY_INFO),
        withLatestFrom(state$),
        exhaustMap(([action, state]) => {
            const jobUUID = getJobUUID(state);
            return patch(`${JOBS_ENDPOINT}${jobUUID}/`, action.payload);
        }),
        withLatestFrom(state$),
        mergeMap(([results, state]) => {
            const normalized = normalize([results.response], [job]);
            const signupFlow = getSignupFlowType(state);
            return dispatchObservable({
                action$,
                state$,
                initialDispatch: [
                    analyticsOnboardingAddNetPay({ signupFlow }),
                    replaceJobs(normalized.entities[JOBS]),
                    refreshAccessToken(),
                ],
                waitFor: [STORE_REPLACE_AUTH],
                timeout: REPLACE_AUTH_TIMEOUT,
                waitForDispatch: () => {
                    const returnActions = [salaryInfoSuccess(results.response)];
                    if (getIsCardFirstSignupFlow(state)) {
                        returnActions.push(
                            reduxStepsSetStep(
                                CARD_ONBOARDING_MVP_2_STEPS,
                                ADD_ADDRESS_STEP,
                            ),
                            reduxStepsSetStep(
                                Object.values(metalSteps),
                                metalSteps.ADD_ADDRESS_STEP,
                            ),
                            routeToLocation(paths.cardOnboarding.path),
                        );
                    } else if (getIsGateCardApply(state)) {
                        returnActions.push(
                            resetGateCardApply(),
                            routeToLocation({
                                path: paths.cardOnboarding.path,
                                replace: true,
                            }),
                        );
                    }
                    return returnActions;
                },
                timeoutDispatch: () => {
                    throw new Error('Loader');
                },
            });
        }),
        handleErrorMessageWithFallback((error) => [salaryInfoError(error)]),
    );
}

const getCompanyUUID = (payload) => payload.company.uuid;
const getCompanyName = (payload) => payload.company.name;
const getCompanySelfOnboardingData = (payload) => payload.selfOnboardingData;
const getEstimatedNetPay = (payload) => payload.estimatedNetPay;
const getPayCycle = (payload) => payload.payCycle;

export function primaryJob(action$, state$, { post }) {
    return action$.pipe(
        ofType(BACKEND_CREATE_PRIMARY_JOB),
        exhaustMap((action) =>
            post(JOBS_ENDPOINT, {
                company: {
                    uuid: getCompanyUUID(action.payload),
                },
                estimatedNetPay: getEstimatedNetPay(action.payload),
                payCycle: getPayCycle(action.payload),
                selfOnboardingData: getCompanySelfOnboardingData(
                    action.payload,
                ),
            }),
        ),
        withLatestFrom(state$),
        mergeMap(([results, state]) => {
            let updateAction;
            if (results.response.company) {
                const normalized = normalize([results.response], [job]);
                updateAction = replaceJobs(normalized.entities[JOBS]);
            }

            const signupFlow = getSignupFlowType(state);

            return [
                analyticsOnboardingAddCompany({
                    company: getCompanyName(results.response),
                    signupFlow,
                }),
                refreshAccessToken(),
                primaryJobSuccess(results.response),
                ...(updateAction ? [updateAction] : []),
            ];
        }),
        handleErrorMessageWithFallback((error) => [primaryJobError(error)]),
    );
}

function getMobilePhone(payload) {
    if (payload.mobilePhone.startsWith('+1')) {
        return payload.mobilePhone;
    }

    return `+1${payload.mobilePhone}`;
}

export function setPhone(action$, state$, { post }) {
    return action$.pipe(
        ofType(BACKEND_CREATE_PHONE),
        exhaustMap((action) =>
            post(SET_PHONE_ENDPOINT, {
                mobilePhone: getMobilePhone(action.payload),
            }),
        ),
        withLatestFrom(state$),
        mergeMap(([, state]) => {
            const returnActions = [setPhoneSuccess()];
            if (!isInPartnerOnboardingFlow(state)) {
                returnActions.push(
                    analyticsOnboardingAddPhoneNumber({
                        signupFlow: getSignupFlowType(state),
                    }),
                );
            }
            return returnActions;
        }),
        handleErrorMessageWithFallback((error) => [setPhoneError(error)]),
    );
}

export function verifyPhone(action$, state$, { put }) {
    return action$.pipe(
        ofType(BACKEND_VERIFY_PHONE),
        exhaustMap((action) => put(SET_PHONE_ENDPOINT, action.payload)),
        withLatestFrom(state$),
        mergeMap(([, state]) => {
            const partnerCode = authentication.getPartnerOnboardedCode();
            const partner = getPartnerNameFromCode(partnerCode);
            const signupFlow = getSignupFlowType(state);
            return dispatchObservable({
                action$,
                state$,
                initialDispatch: [
                    refreshAccessToken(),
                    analyticsOnboardingVerifyPhoneNumber({
                        signupFlow,
                        partner,
                    }),
                ],
                waitFor: [STORE_REPLACE_AUTH],
                waitForDispatch: () => {
                    // NOTE:
                    // This works because verifyPhone is not the last step
                    // before the new user landing page.
                    // If we need verifyPhone to be the last step,
                    // then we'll have to change this logic.
                    if (getIsSignupComplete(state)) {
                        return [
                            completeSignupRequirements(),
                            postAuthRedirect(),
                        ];
                    }

                    // Advance the signup flow to the next step
                    return [verifyPhoneSuccess()];
                },
            });
        }),
        handleErrorMessageWithFallback((error) => [verifyPhoneError(error)]),
    );
}

export function verifyCardInviteCode(action$, state$, { get }) {
    return action$.pipe(
        ofType(BACKEND_VERIFY_CARD_INVITE_CODE),
        switchMap((action) => {
            const queryParameters = `partner_name=${action.payload.partnerName}&partner_code=${action.payload.partnerCode}`;
            const endpoint = `${VERIFY_CARD_INVITE_CODE_ENDPOINT}?${queryParameters}`;
            return get(endpoint);
        }),
        mergeMap((results) => [verifyCardInviteCodeSuccess(results.response)]),
        handleErrorMessageWithFallback((error) => [
            verifyCardInviteCodeError(error),
        ]),
    );
}
