import {
    SHOP_AUDIO_PATH,
    SHOP_HANDBAGS_PATH,
    SHOP_GAMING_PATH,
    SHOP_HOUSEHOLD_BASICS_PATH,
    SHOP_KIDS_PATH,
    SHOP_HOUSEWARES_PATH,
    SHOP_KITCHEN_PATH,
} from '@perpay-web/fintech/constants/paths';

export const AUDIO_CATEGORY = 'audio';
export const HANDBAGS_CATEGORY = 'handbags';
export const VIEDO_GAMES_CATEGORY = 'video games';
export const HOUSEHOLD_BASICS_CATEGORY = 'basics';
export const KIDS_CATEGORY = 'toys';
export const HOUSEWARES_CATEGORY = 'housewares';
export const KITCHEN_CATEGORY = 'kitchen';

export const SHOP_PATHS_BY_CATEGORY = {
    [AUDIO_CATEGORY]: SHOP_AUDIO_PATH,
    [HANDBAGS_CATEGORY]: SHOP_HANDBAGS_PATH,
    [VIEDO_GAMES_CATEGORY]: SHOP_GAMING_PATH,
    [HOUSEHOLD_BASICS_CATEGORY]: SHOP_HOUSEHOLD_BASICS_PATH,
    [KIDS_CATEGORY]: SHOP_KIDS_PATH,
    [HOUSEWARES_CATEGORY]: SHOP_HOUSEWARES_PATH,
    [KITCHEN_CATEGORY]: SHOP_KITCHEN_PATH,
};
