export const CONFIRM_EMPLOYER_STEP = 'confirmEmployer';
export const SELECT_AMOUNT_STEP = 'selectAmount';
export const PINWHEEL_INSTRUCTIONS_COMPLETE_STEP =
    'pinwheelInstructionsComplete';
export const PINWHEEL_INTRO_STEP = 'pinwheelIntro';
export const PINWHEEL_INTRO_CURRENT_DEDUCTION_STEP =
    'pinwheelIntroReturningBorrower';
export const PINWHEEL_MODAL_STEP = 'pinwheelModal';
export const PINWHEEL_SUCCESS_STEP = 'pinwheelSuccess';
export const PINWHEEL_FAILURE_STEP = 'pinwheelFailure';
export const PINWHEEL_CANNOT_FIND_PAYROLL_PROVIDER_STEP =
    'pinwheelCannotFindPayrollProvider';
export const PINWHEEL_EXIT_SURVEY_STEP = 'exitSurvey';
export const ABORT_PINWHEEL_STEP = 'abortPinwheel';
export const PINWHEEL_UNAVAILABLE_STEP = 'pinwheelUnavailable';
export const PAYMENT_SETUP_DETAILS_STEP = 'paymentSetupDetailsStep';
export const PAYMENT_SETUP_DETAILS_RETURNING_BORROWER_STEP =
    'paymentSetupDetailsReturningBorrowerStep';
export const PAYMENT_SETUP_MARKTPLACE_USER_HIGHER_DIRECT_DEPOSIT_STEP =
    'paymentSetupMarketplaceUserHigherDirectDepositStep';

export const PINWHEEL_STEPS = [
    PAYMENT_SETUP_DETAILS_STEP,
    PAYMENT_SETUP_DETAILS_RETURNING_BORROWER_STEP,
    CONFIRM_EMPLOYER_STEP,
    SELECT_AMOUNT_STEP,
    PINWHEEL_INTRO_STEP,
    PINWHEEL_INTRO_CURRENT_DEDUCTION_STEP,
    PINWHEEL_MODAL_STEP,
    PINWHEEL_SUCCESS_STEP,
    PINWHEEL_FAILURE_STEP,
    PINWHEEL_CANNOT_FIND_PAYROLL_PROVIDER_STEP,
    PINWHEEL_INSTRUCTIONS_COMPLETE_STEP,
    PINWHEEL_EXIT_SURVEY_STEP,
    ABORT_PINWHEEL_STEP,
    PINWHEEL_UNAVAILABLE_STEP,
    PAYMENT_SETUP_MARKTPLACE_USER_HIGHER_DIRECT_DEPOSIT_STEP,
];

// This event is defined by the third-party pinwheel API
// https://docs.pinwheelapi.com/docs/link-1#link-events
export const PINWHEEL_SUCCESS_EVENT = 'success';
export const PINWHEEL_LOGIN_EVENT = 'login';

export const PERPAY_MODAL_CONTEXT_PAYMENT_SETUP = 'paymentSetup';
export const PERPAY_MODAL_CONTEXT_CARD_ONBOARDING =
    'perpayCreditCardOnboarding';
export const PERPAY_MODAL_CONTEXT_CARD_ONBOARDING_MVP2 =
    'perpayCreditCardOnboardingMVP2';
export const PERPAY_MODAL_CONTEXT_UPDATE_DIRECT_DEPOSIT = 'updateDirectDeposit';
export const PERPAY_MODAL_CONTEXT_PARTNER_HOSTED_CARD_APP =
    'partner_hosted_card_app';
